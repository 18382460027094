import axios from '../api/api';
import {
    FETCH_MY_WEBSITES_REQUEST,
    FETCH_MY_WEBSITES_SUCCESS,
    FETCH_MY_WEBSITES_FAILURE
} from '../constants/myWebsitesActionTypes';

export const fetchMyWebsites = (userId, userRole) => async (dispatch) => {
    dispatch({ type: FETCH_MY_WEBSITES_REQUEST });
    try {
        const endpoint = userRole === 'admin' ? '/api/websites/' : `/api/websites?sellerId=${userId}`;
        const response = await axios.get(endpoint, {
            headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
        });

        // Ensure rejection status and reason are available
        dispatch({ type: FETCH_MY_WEBSITES_SUCCESS, payload: response.data });
    } catch (error) {
        dispatch({ type: FETCH_MY_WEBSITES_FAILURE, payload: error.message });
        console.error('Failed to fetch websites:', error);
    }
};

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {
  notification,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Pagination,
  Select,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

// Helper function to get CSRF token from cookies
function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

// Axios instance for API requests
const api = axios.create({
  baseURL: "https://writeshuttle.com", // Django backend URL
  headers: {
    "Content-Type": "application/json",
    "X-CSRFToken": getCookie("csrftoken"),
  },
});

api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// API functions for managing data
const fetchDataFunctions = {
  Country: async () => api.get("/api/countries/").then((res) => res.data),
  Language: async () => api.get("/api/languages/").then((res) => res.data),
  Category: async () => api.get("/api/categories/").then((res) => res.data),
};

const addDataFunctions = {
  Country: async (data) =>
    api.post("/api/countries/", data).then((res) => res.data),
  Language: async (data) =>
    api.post("/api/languages/", data).then((res) => res.data),
  Category: async (data) =>
    api.post("/api/categories/", data).then((res) => res.data),
};

const updateDataFunctions = {
  Country: async (id, data) =>
    api.put(`/api/countries/${id}/`, data).then((res) => res.data),
  Language: async (id, data) =>
    api.put(`/api/languages/${id}/`, data).then((res) => res.data),
  Category: async (id, data) =>
    api.put(`/api/categories/${id}/`, data).then((res) => res.data),
};

const deleteDataFunctions = {
  Country: async (id) =>
    api.delete(`/api/countries/${id}/`).then((res) => res.data),
  Language: async (id) =>
    api.delete(`/api/languages/${id}/`).then((res) => res.data),
  Category: async (id) =>
    api.delete(`/api/categories/${id}/`).then((res) => res.data),
};

const DataManager = () => {
  const [items, setItems] = useState([]);
  const [dataType, setDataType] = useState("Country"); // Default type
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [newItemName, setNewItemName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);

  const loadData = useCallback(async () => {
    try {
      const data = await fetchDataFunctions[dataType]();
      setItems(data);
    } catch {
      notification.error({ message: `Failed to load ${dataType}.` });
    }
  }, [dataType]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleAdd = async () => {
    if (!newItemName.trim()) return;

    try {
      const data = await addDataFunctions[dataType]({ name: newItemName });
      setItems([...items, data]);
      setNewItemName("");
      setShowModal(false);
      notification.success({ message: `${dataType} added successfully.` });
    } catch {
      notification.error({ message: `Failed to add ${dataType}.` });
    }
  };

  const handleEdit = (item) => {
    setCurrentItem(item);
    setNewItemName(item.name);
    setIsEditing(true);
    setShowModal(true);
  };

  const handleUpdate = async () => {
    if (!newItemName.trim()) return;

    try {
      await updateDataFunctions[dataType](currentItem.id, {
        name: newItemName,
      });
      setItems(
        items.map((item) =>
          item.id === currentItem.id ? { ...item, name: newItemName } : item
        )
      );
      setNewItemName("");
      setIsEditing(false);
      setShowModal(false);
      notification.success({ message: `${dataType} updated successfully.` });
    } catch {
      notification.error({ message: `Failed to update ${dataType}.` });
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDataFunctions[dataType](id);
      setItems(items.filter((item) => item.id !== id));
      notification.success({ message: `${dataType} deleted successfully.` });
    } catch {
      notification.error({ message: `Failed to delete ${dataType}.` });
    }
  };

  const handleOpenModal = () => {
    setIsEditing(false);
    setNewItemName("");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setCurrentItem(null);
    setNewItemName("");
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: `${dataType} Name`,
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, item) => (
        <>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(item)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(item.id)}
            danger
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="container my-4">
      <h2>{dataType} Manager</h2>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          marginBottom: 20,
        }}
      >
        <Select
          defaultValue={dataType}
          onChange={(value) => setDataType(value)}
          style={{ width: 200 }}
        >
          <Select.Option value="Country">Country</Select.Option>
          <Select.Option value="Language">Language</Select.Option>
          <Select.Option value="Category">Category</Select.Option>
        </Select>

        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleOpenModal}
        >
          Add {dataType}
        </Button>
      </div>

      <Table
        dataSource={currentItems}
        columns={columns}
        rowKey="id"
        pagination={false}
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Pagination
            current={currentPage}
            pageSize={itemsPerPage}
            total={items.length}
            onChange={paginate}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <span style={{ marginRight: "8px" }}>Items per page:</span>
            <Select
              value={itemsPerPage}
              style={{ width: "80px" }}
              onChange={(value) => {
                setItemsPerPage(value);
                setCurrentPage(1);
              }}
            >
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={8}>8</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={12}>12</Select.Option>
              <Select.Option value={20}>20</Select.Option>
            </Select>
          </div>
        </div>
      </div>

      <Modal
        title={isEditing ? `Edit ${dataType}` : `Add ${dataType}`}
        open={showModal}
        onCancel={handleCloseModal}
        onOk={isEditing ? handleUpdate : handleAdd}
      >
        <Form layout="vertical">
          <Form.Item label={`${dataType} Name`} required>
            <Input
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DataManager;

// src/App.js
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes'; // Import the AppRoutes component
import 'react-toastify/dist/ReactToastify.css';

function App() {
    return (
        <Router>
            <AppRoutes /> {/* Use the routes defined in AppRoutes */}
        </Router>
    );
}

export default App;

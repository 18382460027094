export const FETCH_DASHBOARD_DATA_REQUEST = 'FETCH_DASHBOARD_DATA_REQUEST';
export const FETCH_DASHBOARD_DATA_SUCCESS = 'FETCH_DASHBOARD_DATA_SUCCESS';
export const FETCH_DASHBOARD_DATA_FAILURE = 'FETCH_DASHBOARD_DATA_FAILURE';
export const DELETE_WEBSITE_SUCCESS = 'DELETE_WEBSITE_SUCCESS';
export const DELETE_WEBSITE_FAILURE = 'DELETE_WEBSITE_FAILURE';

// New action types for submitting an order
export const SUBMIT_ORDER_REQUEST = 'SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE';
export const CLEAR_ORDER_MESSAGE = 'CLEAR_ORDER_MESSAGE';

import {
    FETCH_WEBSITES_REQUEST,
    FETCH_WEBSITES_SUCCESS,
    FETCH_WEBSITES_FAILURE,
    APPROVE_WEBSITE_SUCCESS,
    REJECT_WEBSITE_SUCCESS,
    TOGGLE_WEBSITE_STATUS_SUCCESS
} from '../constants/mwebsiteActionTypes';

const initialState = {
    websites: [],
    loading: false,
    error: null
};

const mwebsiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WEBSITES_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_WEBSITES_SUCCESS:
            return { ...state, loading: false, websites: action.payload };
        case FETCH_WEBSITES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case APPROVE_WEBSITE_SUCCESS:
            return {
                ...state,
                websites: state.websites.map(site => site.id === action.payload ? { ...site, is_approved: true, is_rejected: false, rejection_reason: '' } : site)
            };
        case REJECT_WEBSITE_SUCCESS:
            return {
                ...state,
                websites: state.websites.map(site => site.id === action.payload.id ? { ...site, is_approved: false, is_rejected: true, rejection_reason: action.payload.reason } : site)
            };
        case TOGGLE_WEBSITE_STATUS_SUCCESS:
            return {
                ...state,
                websites: state.websites.map(site => site.id === action.payload.id ? { ...site, is_active: action.payload.isActive } : site)
            };
        default:
            return state;
    }
};

export default mwebsiteReducer;

import {
    FETCH_DASHBOARD_DATA_REQUEST,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_DASHBOARD_DATA_FAILURE,
    DELETE_WEBSITE_SUCCESS
} from '../constants/dashboardActionTypes';

const initialState = {
    loading: false,
    websites: [],
    users: [],
    role: 'N/A',
    userId: null,
    error: ''
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_DASHBOARD_DATA_REQUEST:
            return { ...state, loading: true };
            case FETCH_DASHBOARD_DATA_SUCCESS:
                return {
                    ...state,
                    loading: false,
                    websites: action.payload.websites || [],
                    users: action.payload.users || [],
                    role: action.payload.role || sessionStorage.getItem('user_role') || 'N/A',
                    userId: sessionStorage.getItem('user_id') || null,  // Fetch user ID from sessionStorage
                    error: ''
                };
            
        case FETCH_DASHBOARD_DATA_FAILURE:
            return { ...state,
                 loading: false, 
                 error: action.error.message ||  'Failed to fetch dashboard data'
                };
        case DELETE_WEBSITE_SUCCESS:
            return {
                ...state,
                websites: state.websites.filter((site) => site.id !== action.payload)
            };
        default:
            return state;
    }
};

export default dashboardReducer;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders, updateOrder, deleteOrder } from "../actions/orderActions";
import { Modal, Form } from "react-bootstrap";
import {
  Button,
  Table,
  Pagination,
  Tag,
  Row,
  Col,
  Card,
  Statistic,
  notification,
  DatePicker,
  Select,
  Input,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BuyerOrders = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [updatedRequirements, setUpdatedRequirements] = useState("");
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [pageSize, setPageSize] = useState(5);
  const [dailyOrdersData, setDailyOrdersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortedOrders, setSortedOrders] = useState([]);

  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (orders.length > 0) {
      const sorted = [...orders].sort(
        (a, b) => new Date(b.order_date) - new Date(a.order_date)
      );
      setSortedOrders(sorted);
      createDailyOrdersData(sorted);
    }
  }, [orders]);

  useEffect(() => {
    const maxPage = Math.ceil(sortedOrders.length / pageSize);
    if (currentPage > maxPage && maxPage > 0) {
      setCurrentPage(maxPage);
    }
  }, [currentPage, sortedOrders.length, pageSize]);

  const createDailyOrdersData = (orders) => {
    const dailyCount = {};
    orders.forEach((order) => {
      const date = order.order_date
        ? new Date(order.order_date).toISOString().split("T")[0]
        : null;
      if (date) {
        dailyCount[date] = (dailyCount[date] || 0) + 1;
      }
    });
    const dailyOrdersArray = Object.entries(dailyCount).map(
      ([date, count]) => ({ date, count, key: date }) // Add key here
    );
    setDailyOrdersData(dailyOrdersArray);
  };

  const handleEditOrder = (order) => {
    setSelectedOrder(order);
    setUpdatedRequirements(order.special_requests || "");
    setShowEditModal(true);
  };

  const handleUpdateOrder = () => {
    dispatch(updateOrder(selectedOrder.id, updatedRequirements));
    notification.success({
      message: "Order Updated",
      description: "Your order has been updated successfully!",
      duration: 2,
    });
    setShowEditModal(false);
  };

  const handleDeleteOrder = (orderId) => {
    dispatch(deleteOrder(orderId));
    notification.success({
      message: "Order Deleted",
      description: "Your order has been deleted successfully!",
      duration: 2,
    });
  };

  const handleShowDetails = (order) => {
    setSelectedOrder(order);
    setShowDetailModal(true);
  };

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  const filteredOrders = sortedOrders.filter((order) => {
    const matchesSearchQuery =
      order.id.toString().includes(searchQuery) ||
      (order.website_name &&
        order.website_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (order.status &&
        order.status.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesSelectedDate =
      !selectedDate ||
      (order.order_date &&
        new Date(order.order_date).toISOString().split("T")[0] ===
          selectedDate.format("YYYY-MM-DD"));

    return matchesSearchQuery && matchesSelectedDate;
  });

  const filteredGraphData = dailyOrdersData.filter((item) => {
    return !selectedDate || item.date === selectedDate.format("YYYY-MM-DD");
  });

  const totalOrders = sortedOrders.length;
  const completedOrders = sortedOrders.filter(
    (order) => order.status === "completed"
  ).length;
  const pendingOrders = sortedOrders.filter(
    (order) => order.status === "pending"
  ).length;
  const canceledOrders = sortedOrders.filter(
    (order) => order.status === "canceled"
  ).length;

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Website Name",
      dataIndex: "website_name",
      key: "name",
      render: (text) => (
        <h3 style={{ fontFamily: "SF Pro" }}>
          {text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
        </h3>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `$${price}`,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color =
          status === "completed"
            ? "green"
            : status === "pending"
            ? "orange"
            : "red";
        return (
          <Tag color={color}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Tag>
        );
      },
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (date) => {
        if (!date) return "N/A";
        const formattedDate = new Date(date).toLocaleString("en-UK", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <>
          <Button
            type="link"
            icon={<InfoCircleOutlined />}
            onClick={() => handleShowDetails(record)}
          >
            Details
          </Button>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEditOrder(record)}
          >
            Edit
          </Button>
          <Button
            type="link"
            icon={<DeleteOutlined />}
            onClick={() => handleDeleteOrder(record.id)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const currentOrders = filteredOrders.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="container mt-4">
      <h2>Your Orders</h2>

      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Card style={{ backgroundColor: "#FFF3E0", borderColor: "#FFB74D" }}>
            <Statistic
              title="Total Orders"
              value={totalOrders}
              valueStyle={{ color: "#FB8C00" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Card style={{ backgroundColor: "#E8F5E9", borderColor: "#66BB6A" }}>
            <Statistic
              title="Completed Orders"
              value={completedOrders}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Card style={{ backgroundColor: "#FFFDE7", borderColor: "#FFD54F" }}>
            <Statistic
              title="Pending Orders"
              value={pendingOrders}
              valueStyle={{ color: "#faad14" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Card style={{ backgroundColor: "#FFEBEE", borderColor: "#E57373" }}>
            <Statistic
              title="Canceled Orders"
              value={canceledOrders}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={16} style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Card
            title="Daily Orders Overview"
            style={{ backgroundColor: "#E3F2FD", borderColor: "#64B5F6" }}
          >
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={filteredGraphData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#B3E5FC" />
                <XAxis dataKey="date" stroke="#0277BD" />
                <YAxis stroke="#0277BD" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={12} md={6}>
          <Input
            placeholder="Search by Order ID, Website Name, or Status"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <DatePicker
            onChange={onDateChange}
            value={selectedDate}
            placeholder="Select Date"
            allowClear
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Table
        dataSource={currentOrders}
        columns={columns}
        rowKey="id"
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <div
        className="pagination-controls"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredOrders.length}
          onChange={(page) => setCurrentPage(page)}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Items per page:</span>
          <Select
            value={pageSize}
            style={{ width: 80 }}
            onChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
      </div>

      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="requirements">
              <Form.Label>Additional Requirements</Form.Label>
              <Form.Control
                as="textarea"
                rows={6}
                value={updatedRequirements}
                onChange={(e) => setUpdatedRequirements(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="link"
            icon={<CloseCircleFilled />}
            onClick={() => setShowEditModal(false)}
          >
            Cancel
          </Button>
          <Button
            type="link"
            icon={<CheckCircleFilled />}
            onClick={handleUpdateOrder}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
  show={showDetailModal}
  onHide={() => setShowDetailModal(false)}
  size="lg"
  style={{ maxWidth: "90vw", margin: "0 auto" }} // Limits width on smaller screens
>
  <Modal.Header closeButton>
    <Modal.Title>Order Details</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedOrder && (
      <Table
        bordered
        pagination={false}
        columns={[
          { title: "Detail", dataIndex: "label", key: "label" },
          { title: "Information", dataIndex: "value", key: "value" },
        ]}
        dataSource={[
          { key: "order-id", label: "Order ID", value: selectedOrder.id },
          {
            key: "website-name",
            label: "Website Name",
            value: selectedOrder.website_name || "N/A",
          },
          {
            key: "price",
            label: "Price",
            value: `$${selectedOrder.price || "N/A"}`,
          },
          {
            key: "status",
            label: "Status",
            value: selectedOrder.status || "N/A",
          },
          {
            key: "order-date",
            label: "Order Date",
            value: selectedOrder.order_date
              ? new Date(selectedOrder.order_date).toLocaleString()
              : "N/A",
          },
          {
            key: "special-requests",
            label: "Special Requirements",
            value: selectedOrder.special_requests || "N/A",
          },
          {
            key: "description",
            label: "Description",
            value: selectedOrder.website_details?.description || "N/A",
          },
          {
            key: "monthly-traffic",
            label: "Monthly Traffic",
            value: selectedOrder.website_details?.monthly_traffic || "N/A",
          },
          {
            key: "link-type",
            label: "Link Type",
            value: selectedOrder.website_details?.link_type || "N/A",
          },
          {
            key: "domain-rating",
            label: "Domain Rating (DR)",
            value: selectedOrder.website_details?.domain_rating || "N/A",
          },
          {
            key: "domain-authority",
            label: "Domain Authority (DA)",
            value: selectedOrder.website_details?.domain_authority || "N/A",
          },
          {
            key: "page-authority",
            label: "Page Authority (PA)",
            value: selectedOrder.website_details?.page_authority || "N/A",
          },
        ]}
        scroll={{ x: true }} // Enables horizontal scroll on smaller screens
      />
    )}
  </Modal.Body>
  <Modal.Footer>
    <Button
      type="link"
      icon={<CloseCircleFilled />}
      onClick={() => setShowDetailModal(false)}
    >
      Close
    </Button>
  </Modal.Footer>
</Modal>

    </div>
  );
};

export default BuyerOrders;

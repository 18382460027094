import { applyMiddleware } from 'redux';
import { legacy_createStore as createStore } from 'redux';
import { combineReducers } from 'redux';
import {thunk} from 'redux-thunk';
import websiteReducer from '../reducers/websiteReducer';
import dashboardReducer from '../reducers/dashboardReducer';
import addwebsiteReducer from '../reducers/addwebsiteReducer';
import editwebsiteReducer from '../reducers/editwebsiteReducer';
import userReducer from '../reducers/userReducer';
import mwebsiteReducer from '../reducers/mwebsiteReducer';
import myWebsitesReducer from '../reducers/myWebsitesReducer';
import authReducer from '../reducers/authReducer';
import { orderReducer } from '../reducers/orderReducer';

// Combine your reducers
const rootReducer = combineReducers({
    website: websiteReducer,
    dashboard: dashboardReducer,
    addwebsite: addwebsiteReducer,
    editwebsite: editwebsiteReducer,
    user: userReducer,
    mwebsite: mwebsiteReducer,
    myWebsites: myWebsitesReducer,
    auth: authReducer,
    order: orderReducer,
});

// Create the Redux store with middleware (without DevTools extension)
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
);

export default store;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchWebsite, updateWebsite, resetUpdateSuccess } from '../actions/editwebsiteActions';
import api from '../api/api';  // Assuming you have an API instance for fetching data
import { Form, Input, Button, Alert, Spin, Row, Col, Card, Checkbox, notification } from 'antd';

const EditWebsite = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const { website, loading, error, updateSuccess } = useSelector((state) => state.editwebsite);

    // Define all state variables needed for form fields
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [price, setPrice] = useState('');
    const [monthlyTraffic, setMonthlyTraffic] = useState('');
    const [linkType, setLinkType] = useState('');
    const [numberOfWords, setNumberOfWords] = useState('');
    const [domainRating, setDomainRating] = useState('');
    const [domainAuthority, setDomainAuthority] = useState('');
    const [pageAuthority, setPageAuthority] = useState('');
    const [description, setDescription] = useState('');

    // State for available options
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    
    // State for selected options
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);

    // Fetch website data and available options when component mounts
    useEffect(() => {
        dispatch(fetchWebsite(id));

        const fetchOptions = async () => {
            try {
                const countryResponse = await api.get('/api/countries/', {
                    headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
                });
                setCountries(countryResponse.data);

                const languageResponse = await api.get('/api/languages/', {
                    headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
                });
                setLanguages(languageResponse.data);

                const categoryResponse = await api.get('/api/categories/', {
                    headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
                });
                setCategories(categoryResponse.data);
            } catch (error) {
                console.error("Failed to fetch options:", error);
            }
        };

        fetchOptions();
    }, [dispatch, id]);

    // Populate form fields with fetched website data
    useEffect(() => {
        if (website && website.id) {
            setName(website.name || '');
            setUrl(website.url || '');
            setPrice(parseFloat(website.price) || '');
            setDescription(website.description || '');
            setMonthlyTraffic(website.monthly_traffic || '');
            setLinkType(website.link_type || '');
            setNumberOfWords(website.number_of_words || '');
            setDomainRating(parseFloat(website.domain_rating) || '');
            setDomainAuthority(parseFloat(website.domain_authority) || '');
            setPageAuthority(parseFloat(website.page_authority) || '');
            setSelectedCountries(website.countries || []);
            setSelectedLanguages(website.languages || []);
            setSelectedCategories(website.categories || []);
        }
    }, [website]);

    // Handle successful update and navigation with notification
    useEffect(() => {
        if (updateSuccess) {
            notification.success({
                message: 'Update Successful',
                description: 'Website updated successfully!',
                duration: 2,  // 2 seconds
            });
            navigate('/dashboard');
            dispatch(resetUpdateSuccess());
        }
    }, [updateSuccess, navigate, dispatch]);

    // Submit updated data
    const handleSubmit = () => {
        dispatch(updateWebsite(
            id,
            name,
            url,
            price,
            selectedCountries,
            selectedLanguages,
            selectedCategories,
            monthlyTraffic,
            linkType,
            numberOfWords,
            domainRating,
            domainAuthority,
            pageAuthority,
            description
        ));
    };

    // Display loading spinner while fetching data
    if (loading) {
        return <div className="text-center mt-5"><Spin size="large" /></div>;
    }

    if (!loading && (!website || !website.id)) {
        return <div className="text-center mt-5">No website data available.</div>;
    }

    return (
        <Row justify="center" align="middle" style={{ minHeight: '100vh', padding: '20px' }}>
            <Col xs={24} sm={16} md={18} lg={16}>
                <Card bordered style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
                    <h2 style={{ textAlign: 'center', marginBottom: '24px' }}>Edit Website</h2>
                    {error && <Alert message={error} type="error" showIcon style={{ marginBottom: '20px' }} />}
                    <Form onFinish={handleSubmit} layout="vertical">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item label="Website Name" required>
                                    <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter website name" />
                                </Form.Item>
                                <Form.Item label="Website URL" required>
                                    <Input type="url" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://example.com" />
                                </Form.Item>
                                <Form.Item label="Price" required>
                                    <Input type="number" value={price} onChange={(e) => setPrice(e.target.value)} placeholder="Enter price" />
                                </Form.Item>
                                <Form.Item label="Monthly Traffic">
                                    <Input type="number" value={monthlyTraffic} onChange={(e) => setMonthlyTraffic(e.target.value)} placeholder="Enter monthly traffic" />
                                </Form.Item>
                                <Form.Item label="Link Type">
                                    <Input value={linkType} onChange={(e) => setLinkType(e.target.value)} placeholder="Enter link type" />
                                </Form.Item>
                                <Form.Item label="Number of Words">
                                    <Input type="number" value={numberOfWords} onChange={(e) => setNumberOfWords(e.target.value)} placeholder="Enter number of words" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Domain Rating">
                                    <Input type="number" value={domainRating} onChange={(e) => setDomainRating(e.target.value)} placeholder="Enter domain rating" />
                                </Form.Item>
                                <Form.Item label="Domain Authority">
                                    <Input type="number" value={domainAuthority} onChange={(e) => setDomainAuthority(e.target.value)} placeholder="Enter domain authority" />
                                </Form.Item>
                                <Form.Item label="Page Authority">
                                    <Input type="number" value={pageAuthority} onChange={(e) => setPageAuthority(e.target.value)} placeholder="Enter page authority" />
                                </Form.Item>
                                <Form.Item label="Description">
                                    <Input.TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter description" />
                                </Form.Item>
                                <Form.Item label="Available in Countries">
                                    <Checkbox.Group
                                        value={selectedCountries}
                                        onChange={setSelectedCountries}
                                        options={countries.map((country) => ({ label: country.name, value: country.id }))}
                                    />
                                </Form.Item>
                                <Form.Item label="Available Languages">
                                    <Checkbox.Group
                                        value={selectedLanguages}
                                        onChange={setSelectedLanguages}
                                        options={languages.map((language) => ({ label: language.name, value: language.id }))}
                                    />
                                </Form.Item>
                                <Form.Item label="Categories">
                                    <Checkbox.Group
                                        value={selectedCategories}
                                        onChange={setSelectedCategories}
                                        options={categories.map((category) => ({ label: category.name, value: category.id }))}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" block loading={loading}>Update Website</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default EditWebsite;

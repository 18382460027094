import axios from '../api/api';
import {
    FETCH_WEBSITE_REQUEST,
    FETCH_WEBSITE_SUCCESS,
    FETCH_WEBSITE_FAILURE,
    UPDATE_WEBSITE_REQUEST,
    UPDATE_WEBSITE_SUCCESS,
    UPDATE_WEBSITE_FAILURE
} from '../constants/editwebsiteActionTypes';

export const fetchWebsite = (id) => {
    return async (dispatch) => {
        dispatch({ type: FETCH_WEBSITE_REQUEST });
        try {
            const response = await axios.get(`/api/websites/${id}/`, {
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
            });
            dispatch({
                type: FETCH_WEBSITE_SUCCESS,
                payload: response.data,
            });
        } catch (error) {
            dispatch({
                type: FETCH_WEBSITE_FAILURE,
                error: error.message,
            });
        }
    };
};

export const updateWebsite = (
    id,
    name,
    url,
    price,
    countries,
    languages,
    categories,
    monthlyTraffic,
    linkType,
    numberOfWords,
    domainRating,
    domainAuthority,
    pageAuthority,
    description 
) => {
    return async (dispatch) => {
        dispatch({ type: UPDATE_WEBSITE_REQUEST });

        // Construct the payload with all the fields required by the API
        const payload = {
            name,
            url,
            price: parseInt(price, 10),
            countries,
            languages,
            categories,
            monthly_traffic: parseInt(monthlyTraffic, 10),
            link_type: linkType,
            number_of_words: parseInt(numberOfWords, 10),
            domain_rating: parseInt(domainRating, 10),
            domain_authority: parseInt(domainAuthority, 10),
            page_authority: parseInt(pageAuthority, 10),
            description 
        };

        // Logging for debugging purposes
        console.log("Updating website with payload:", payload);

        try {
            await axios.put(`/api/websites/${id}/`, payload, {
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
            });
            dispatch({ type: UPDATE_WEBSITE_SUCCESS });
        } catch (error) {
            console.error("Error updating website:", error.response ? error.response.data : error.message);
            dispatch({
                type: UPDATE_WEBSITE_FAILURE,
                error: error.response ? error.response.data : error.message,
            });
        }
    };
};

// Action to reset update success status
export const resetUpdateSuccess = () => ({
    type: 'RESET_UPDATE_SUCCESS'
});

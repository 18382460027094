import axios from '../api/api';
import {
    FETCH_DASHBOARD_DATA_REQUEST,
    FETCH_DASHBOARD_DATA_SUCCESS,
    FETCH_DASHBOARD_DATA_FAILURE,
    DELETE_WEBSITE_SUCCESS,
    DELETE_WEBSITE_FAILURE
} from '../constants/dashboardActionTypes';

export const fetchDashboardData = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_DASHBOARD_DATA_REQUEST });
        try {
            const response = await axios.get('/api/dashboard/', {
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
            });
            console.log("Dashboard API Response:", response.data); // Log to check data
            dispatch({
                type: FETCH_DASHBOARD_DATA_SUCCESS,
                payload: response.data
            });
        } catch (error) {
            dispatch({
                type: FETCH_DASHBOARD_DATA_FAILURE,
                error: error.message
            });
        }
    };
};

export const deleteWebsite = (websiteId) => {
    return async (dispatch) => {
        try {
            await axios.delete(`/api/websites/${websiteId}/`, {
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
            });
            dispatch({
                type: DELETE_WEBSITE_SUCCESS,
                payload: websiteId
            });
        } catch (error) {
            dispatch({
                type: DELETE_WEBSITE_FAILURE,
                error: error.message
            });
        }
    };
};

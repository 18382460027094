import {
    FETCH_MY_WEBSITES_REQUEST,
    FETCH_MY_WEBSITES_SUCCESS,
    FETCH_MY_WEBSITES_FAILURE
} from '../constants/myWebsitesActionTypes';

const initialState = {
    websites: [],
    loading: false,
    error: null
};

const myWebsitesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MY_WEBSITES_REQUEST:
            return { ...state, loading: true, error: null };
        case FETCH_MY_WEBSITES_SUCCESS:
            return { ...state, loading: false, websites: action.payload };
        case FETCH_MY_WEBSITES_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default myWebsitesReducer;

import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWebsites } from "../actions/websiteActions";
import axios from "axios";
import {
  Card,
  Col,
  Row,
  Button,
  Spin,
  Alert,
  Pagination,
  Select,
  Input,
  message,
  Modal,
} from "antd";
import {
  ExportOutlined,
  ShoppingCartOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

const { Meta } = Card;

const PublicWebsites = React.memo(() => {
  const dispatch = useDispatch();
  const { websites, loading, error } = useSelector((state) => state.website);
  const [currentPage, setCurrentPage] = useState(
    () => Number(localStorage.getItem("currentPage")) || 1
  );

const userId = sessionStorage.getItem("user_id");
const userRole = sessionStorage.getItem("user_role");
console.log("User ID:", userId, "User Role:", userRole);
// Updated key
  
  // Log to verify the correct role
  console.log("User ID:", userId, "User Role:", userRole);


  // Log user ID and role
  console.log("User ID:", userId, "User Role:", userRole);

  const [websitesPerPage, setWebsitesPerPage] = useState(12);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
  const [selectedWebsite, setSelectedWebsite] = useState(null);
  const [orderDetails, setOrderDetails] = useState({ requirements: "" });

  useEffect(() => {
    dispatch(fetchWebsites());
  }, [dispatch]);

  useEffect(() => {
    const maxPage = Math.ceil(websites.length / websitesPerPage);
    if (currentPage > maxPage && maxPage > 0) {
      setCurrentPage(maxPage);
    }
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage, websites.length, websitesPerPage]);
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const handlePageSizeChange = (value) => {
    setWebsitesPerPage(value);
    setCurrentPage(1);
  };

  const openOrderModal = (website) => {
    setSelectedWebsite(website);
    setIsOrderModalVisible(true);
  };

  const closeOrderModal = () => {
    setIsOrderModalVisible(false);
    setOrderDetails({ requirements: "" });
    setSelectedWebsite(null);
  };

  const handleOrderSubmit = () => {
    const token = sessionStorage.getItem("token");
    axios
      .post(
        "https://writeshuttle.com/api/order/",
        {
          website_id: selectedWebsite.id,
          special_requests: orderDetails.requirements,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        message.success(
          `Order placed successfully for ${selectedWebsite.name}`
        );
        closeOrderModal();
      })
      .catch((error) => {
        console.error("Error placing order", error);
        message.error(error.response?.data?.error || "Error placing order");
      });
  };

  // Reverse the websites array to show newer ones first
  const reversedWebsites = [...websites].reverse();
  const indexOfLastWebsite = currentPage * websitesPerPage;
  const indexOfFirstWebsite = indexOfLastWebsite - websitesPerPage;
  const currentWebsites = reversedWebsites.slice(
    indexOfFirstWebsite,
    indexOfLastWebsite
  );

  return (
    <div style={{ padding: "20px", marginTop: "20px" }}>
       <h1>User ID: {userId} | Role: {userRole}</h1>

      <Spin spinning={loading} tip="Loading websites...">
        {error ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Alert message="Error" description={error} type="error" showIcon />
          </div>
        ) : (
          <>
            <Row gutter={[16, 16]} style={{ marginTop: "20px" }}>
              {currentWebsites.length > 0 ? (
                currentWebsites.map((site) => (
                  <Col xs={12} sm={8} md={6} lg={4} key={site.id}>
                    <Card
                      hoverable
                      cover={
                        <img
                          alt={site.name}
                          src={site.screenshot}
                          style={{
                            width: "100%",
                            height: "150px",
                            objectFit: "cover",
                            borderRadius: "10px",
                            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
                            backgroundColor: "#f9f9f9",
                          }}
                        />
                      }
                    >
                      <Meta
                        title={
                          <span
                            style={{ fontWeight: "bold", fontSize: "1.1em" }}
                          >
                            {site.name}
                          </span>
                        }
                        description={
                          <span
                            style={{
                              color: "#228B22",
                              fontSize: "0.95em",
                              backgroundColor: "#e0f7e0",
                              padding: "5px 8px",
                              borderRadius: "8px",
                              display: "inline-block",
                              fontWeight: "500",
                            }}
                          >
                            {`Price: $${site.price}`}
                          </span>
                        }
                      />

                      {/* Website Details in two-column layout */}
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "1fr 1fr",
                          gap: "8px",
                          marginTop: "10px",
                          fontSize: "12px",
                          color: "#444",
                          borderTop: "1px solid #eee",
                          paddingTop: "8px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span role="img" aria-label="traffic">
                            📈
                          </span>
                          <span>
                            <strong>Traffic:</strong>{" "}
                            {site.monthly_traffic || "N/A"}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span role="img" aria-label="link-type">
                            🔗
                          </span>
                          <span>
                            <strong>Link:</strong> {site.link_type || "N/A"}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span role="img" aria-label="domain-rating">
                            ⭐
                          </span>
                          <span>
                            <strong>Domain Rating:</strong>{" "}
                            {site.domain_rating || "N/A"}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span role="img" aria-label="domain-authority">
                            📊
                          </span>
                          <span>
                            <strong>Domain Auth:</strong>{" "}
                            {site.domain_authority || "N/A"}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span role="img" aria-label="page-authority">
                            📄
                          </span>
                          <span>
                            <strong>Page Auth:</strong>{" "}
                            {site.page_authority || "N/A"}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          <span role="img" aria-label="description">
                            📝
                          </span>
                          <span>
                            <strong>Description:</strong>{" "}
                            {site.description || "N/A"}
                          </span>
                        </div>
                      </div>

                      <div style={{ marginTop: "10px", textAlign: "center" }}>
                        <Button
                          type="link"
                          href={site.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          icon={<ExportOutlined />}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                          }}
                        >
                          Visit Site
                        </Button>
                        <Button
                          type="link"
                          icon={<ShoppingCartOutlined />}
                          onClick={() => openOrderModal(site)}
                        >
                          Place Order
                        </Button>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <Col span={24}>
                  <p style={{ textAlign: "center" }}>
                    No websites available at the moment. Please check back later.
                  </p>
                </Col>
              )}
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "16px",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
            >
              <Pagination
                current={currentPage}
                pageSize={websitesPerPage}
                total={websites.length}
                onChange={handlePageChange}
              />
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "8px" }}>Items per page:</span>
                <Select
                  value={websitesPerPage}
                  style={{ width: "80px" }}
                  onChange={handlePageSizeChange}
                >
                  <Select.Option value={24}>24</Select.Option>
                  <Select.Option value={48}>48</Select.Option>
                  <Select.Option value={96}>96</Select.Option>
                </Select>
              </div>
            </div>

            {/* Order Modal */}
            <Modal
              title="Place Order"
              open={isOrderModalVisible}
              onCancel={closeOrderModal}
              footer={[
                <Button
                  key="close"
                  icon={<CloseCircleFilled />}
                  onClick={closeOrderModal}
                >
                  Close
                </Button>,
                <Button
                  key="submit"
                  icon={<CheckCircleFilled />}
                  onClick={handleOrderSubmit}
                >
                  Submit Order
                </Button>,
              ]}
            >
              <Input.TextArea
                rows={4}
                placeholder="Add any special requirements"
                value={orderDetails.requirements}
                onChange={(e) =>
                  setOrderDetails({
                    ...orderDetails,
                    requirements: e.target.value,
                  })
                }
              />
            </Modal>
          </>
        )}
      </Spin>
    </div>
  );
});

export default PublicWebsites;

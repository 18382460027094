import {
    ADD_WEBSITE_REQUEST,
    ADD_WEBSITE_SUCCESS,
    ADD_WEBSITE_FAILURE
} from '../constants/websiteActionTypes';

const initialState = {
    loading: false,
    error: null,
};

const websiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_WEBSITE_REQUEST:
            return { ...state, loading: true, error: null };
        case ADD_WEBSITE_SUCCESS:
            return { ...state, loading: false };
        case ADD_WEBSITE_FAILURE:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
};

export default websiteReducer;

import axios from '../api/api';
import {
    FETCH_WEBSITES_REQUEST,
    FETCH_WEBSITES_SUCCESS,
    FETCH_WEBSITES_FAILURE,
} from '../constants/websiteActionTypes';

export const fetchWebsites = () => {
    return async (dispatch) => {
        dispatch({ type: FETCH_WEBSITES_REQUEST });
        try {
            const token = sessionStorage.getItem('token');
            console.log("Fetching Public Websites with Token:", token);  // Log token for debugging

            const response = await axios.get('/api/websites/public/', {
                headers: { 'Authorization': `Token ${token}` }
            });
            console.log("Public Websites Response:", response.data);  // Log response data

            // Filter active websites before dispatching
            const activeWebsites = response.data.filter(site => site.is_active);
            dispatch({ type: FETCH_WEBSITES_SUCCESS, payload: activeWebsites });
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message;
            console.error("Error Fetching Public Websites:", errorMessage);  // Log error message

            dispatch({
                type: FETCH_WEBSITES_FAILURE,
                payload: errorMessage
            });
        }
    };
};

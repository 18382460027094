import React, { useEffect, useRef } from "react";

// Use icons or emojis for simplicity
const singleCheck = "✔️"; // Delivered but unread
const doubleCheck = "✔✔️"; // Read

const MessageList = ({ messages, currentUser, currentUserRole }) => {
  // Retrieve the user roles map from sessionStorage
  const userRoles = JSON.parse(sessionStorage.getItem("userRoles") || "{}");

  // Create a ref for the message list container
  const messagesEndRef = useRef(null);

  // Scroll to the bottom of the message list when messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  return (
    <div
      ref={messagesEndRef}
      style={{
        maxHeight: "400px",
        overflowY: "auto",
        padding: "15px",
        backgroundColor: "#f7f8fa",
        borderRadius: "15px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      {messages.map((msg, index) => {
        const isCurrentUser = msg.sender__email === currentUser;
        const senderRole = userRoles[msg.sender__email] || "Me";
        const isAdminMessage = senderRole === "admin";

        // Determine the checkmark based on the read status of the message
        const checkmark = msg.read ? doubleCheck : singleCheck;

        return (
          <div
            key={index}
            style={{
              display: "flex",
              justifyContent:
                isCurrentUser || isAdminMessage ? "flex-end" : "flex-start",
              padding: "6px 0",
              animation: "fadeIn 0.3s ease",
            }}
          >
            <div
              style={{
                maxWidth: "50%",  // Reduced max width for a narrower message
                padding: "8px",   // Reduced padding for compactness
                borderRadius: "12px",
                background: isCurrentUser
                  ? "linear-gradient(135deg, #6dd5ed, #2193b0)" // Blue gradient for current user
                  : isAdminMessage
                  ? "linear-gradient(135deg, #2c3e50, #3498db)" // Blue gradient for admin
                  // Pink gradient for admin
                  : "linear-gradient(135deg,#4e73df, #1e3c72)", // Grey gradient for other users
                color: isCurrentUser ? "white" : isAdminMessage ? "white" : "white",
                borderTopRightRadius: isCurrentUser ? "5px" : "12px",
                borderTopLeftRadius: isCurrentUser ? "12px" : "5px",
                boxShadow: "0px 2px 6px rgba(0,0,0,0.1)",
                textAlign: isCurrentUser || isAdminMessage ? "right" : "left",
              }}
            >
              {/* Display the sender's role if it's not the current user */}
              {!isCurrentUser && (
                <strong
                  style={{
                    color: isAdminMessage ? "#ff4d4f" : "#555",
                    fontSize: "0.85em",
                  }}
                >
                  {senderRole.charAt(0).toUpperCase() + senderRole.slice(1)}
                </strong>
              )}
              {/* Display the current user's role as "You" */}
              {isCurrentUser && (
                <strong style={{ color: "#ffffff", fontSize: "0.85em" }}>
                  {currentUserRole === "admin"
                    ? "Admin"
                    : currentUserRole === "seller"
                    ? "Seller"
                    : currentUserRole === "buyer"
                    ? "Buyer"
                    : "You"}
                </strong>
              )}
              <div style={{ marginTop: "6px", fontSize: "0.95em" }}>{msg.content}</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  fontSize: "0.8em",
                  color: isCurrentUser ? "#ffffff" : "#888",
                  textAlign: isCurrentUser || isAdminMessage ? "right" : "left",
                  marginTop: "8px",
                }}
              >
                {/* Timestamp */}
                <span>
                  {new Date(Date.parse(msg.timestamp)).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </span>
                {/* Read/Delivered Indicator */}
                <span style={{ marginLeft: "5px", fontSize: "0.9em" }}>
                  {checkmark}
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MessageList;

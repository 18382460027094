import { FETCH_ORDERS, UPDATE_ORDER, DELETE_ORDER, SET_LOADING } from '../constants/orderActionsTypes';

const initialState = {
    orders: [],
    loading: false,
};

export const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ORDERS:
            return { ...state, orders: action.payload };
        case UPDATE_ORDER:
            return {
                ...state,
                orders: state.orders.map(order =>
                    order.id === action.payload.id ? action.payload : order
                ),
            };
        case DELETE_ORDER:
            return { ...state, orders: state.orders.filter(order => order.id !== action.payload) };
        case SET_LOADING:
            return { ...state, loading: action.payload };
        default:
            return state;
    }
};

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { register } from '../actions/authActions';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = ({ register }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        password2: '',
        type: '',
    });
    const [showPassword, setShowPassword] = useState(false);

    const { email, password, password2, type } = formData;
    const navigate = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        if (password === password2) {
            const success = await register({ email, password, password2, type });
            if (success) {
                toast.success('Registration successful! Redirecting to login...');
                navigate('/login', { state: { email } });
            } else {
                toast.error('Registration failed. Please try again.');
            }
        } else {
            toast.error('Passwords do not match.');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div
            style={{
                background: 'linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem',
                width: '100%',
            }}
        >
            <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
            <div style={{ width: '100%', maxWidth: '400px' }}>
                <div
                    className="card shadow-sm border-0"
                    style={{
                        borderRadius: '15px',
                        padding: '2rem',
                    }}
                >
                    <h3 className="card-title mb-4 text-center" style={{ color: '#2575fc' }}>
                        Create Your Account
                    </h3>
                    <form onSubmit={onSubmit}>
                        <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                onChange={onChange}
                                className="form-control"
                                required
                                style={{
                                    backgroundColor: '#f0f4ff',
                                    color: '#333',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                value={password}
                                onChange={onChange}
                                className="form-control"
                                required
                                style={{
                                    backgroundColor: '#f0f4ff',
                                    color: '#333',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Confirm Password</label>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                name="password2"
                                value={password2}
                                onChange={onChange}
                                className="form-control"
                                required
                                style={{
                                    backgroundColor: '#f0f4ff',
                                    color: '#333',
                                    borderRadius: '30px',
                                }}
                            />
                        </div>
                        <div className="mb-3 form-check">
                            <input
                                type="checkbox"
                                checked={showPassword}
                                onChange={togglePasswordVisibility}
                                className="form-check-input"
                            />
                            <label className="form-check-label">Show Password</label>
                        </div>
                        <div className="mb-3">
                            <label className="form-label">User Type</label>
                            <select
                                name="type"
                                value={type}
                                onChange={onChange}
                                className="form-control"
                                required
                                style={{
                                    backgroundColor: '#f0f4ff',
                                    color: '#333',
                                    borderRadius: '30px',
                                }}
                            >
                                <option value="">Select Type</option>
                                <option value="1">Buyer</option>
                                <option value="2">Seller</option>
                            </select>
                        </div>
                        <button
                            type="submit"
                            className="btn btn-primary w-100"
                            style={{
                                backgroundColor: '#2575fc',
                                borderColor: '#2575fc',
                                fontWeight: '500',
                                borderRadius: '30px',
                            }}
                        >
                            Register
                        </button>
                    </form>
                    <div className="text-center mt-3">
                        <span>Already have an account? </span>
                        <a href="/login" className="text-decoration-none" style={{ color: '#2575fc' }}>
                            Log in
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    register: (userData) => dispatch(register(userData)),
});

export default connect(null, mapDispatchToProps)(Register);

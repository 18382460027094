import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { notification, DatePicker } from "antd";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import {
  Table,
  Pagination,
  Row,
  Col,
  Card,
  Statistic,
  Modal,
  Form,
  Input,
  Button,
  Alert,
  Tag,
  Select,
} from "antd";
import {
  SettingOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";

const SellerOrders = () => {
  const [orders, setOrders] = useState([]);
  const [role, setRole] = useState("");
  const [dailyOrdersData, setDailyOrdersData] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [specialRequirements, setSpecialRequirements] = useState("");

  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [ordersPerPage, setOrdersPerPage] = useState(5);

  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleOrdersPerPageChange = (value) => {
    setOrdersPerPage(value);
    setCurrentPage(1);
  };

  const fetchOrders = useCallback(async () => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://writeshuttle.com/api/seller/orders/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      const ordersData = response.data.reverse();
      setOrders(ordersData);

      const dailyCount = {};
      ordersData.forEach((order) => {
        const date = order.order_date
          ? new Date(order.order_date).toISOString().split("T")[0]
          : null;
        if (date) {
          dailyCount[date] = (dailyCount[date] || 0) + 1;
        }
      });

      const dailyOrdersArray = Object.entries(dailyCount).map(
        ([date, count]) => ({ date, count })
      );
      setDailyOrdersData(dailyOrdersArray);
    } catch (error) {
      console.error("Error fetching orders", error);
      setErrorMessage("Error fetching orders. Please try again later.");
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    const maxPage = Math.ceil(orders.length / ordersPerPage);
    if (currentPage > maxPage && maxPage > 0) {
      setCurrentPage(maxPage);
    }
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage, orders.length, ordersPerPage]);

  useEffect(() => {
    const userRole = sessionStorage.getItem("role");
    setRole(userRole);
  }, []);

  const handleOpenModal = (order) => {
    setSelectedOrder(order);
    setSpecialRequirements(order.special_requests || "");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedOrder(null);
    setSpecialRequirements("");
  };

  const handleUpdateStatus = async (status) => {
    const token = sessionStorage.getItem("token");
    try {
      await axios.put(
        `https://writeshuttle.com/api/seller/orders/${selectedOrder.id}/`,
        { status, special_requests: specialRequirements },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      notification.success({
        message: "Success",
        description: `Order marked as ${status}!`,
        duration: 2,
      });
      handleCloseModal();
      fetchOrders();
    } catch (error) {
      console.error("Error updating order status", error);
      notification.error({
        message: "Error",
        description: "Failed to update order status.",
        duration: 2,
      });
    }
  };

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  // Filter orders based on the search query and selected date
  const filteredOrders = orders.filter((order) => {
    const matchesSearchQuery =
      order.id.toString().includes(searchQuery) ||
      (order.website_name &&
        order.website_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (order.status &&
        order.status.toLowerCase().includes(searchQuery.toLowerCase()));

    const matchesSelectedDate =
      !selectedDate ||
      (order.order_date &&
        new Date(order.order_date).toISOString().split("T")[0] ===
          selectedDate.format("YYYY-MM-DD"));

    return matchesSearchQuery && matchesSelectedDate;
  });

  const filteredGraphData = dailyOrdersData.filter((item) => {
    return !selectedDate || item.date === selectedDate.format("YYYY-MM-DD");
  });

  const indexOfLastOrder = currentPage * ordersPerPage;
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  const currentOrders = filteredOrders.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalOrders = orders.length;
  const pendingOrders = orders.filter(
    (order) => order.status === "pending"
  ).length;
  const completedOrders = orders.filter(
    (order) => order.status === "completed"
  ).length;
  const canceledOrders = orders.filter(
    (order) => order.status === "canceled"
  ).length;

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Website Name",
      dataIndex: "website_name",
      key: "name",
      render: (text) => (
        <h3 style={{ fontFamily: "SF Pro" }}>
          {text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
        </h3>
      ),
    },
    {
      title: "Buyer Email",
      dataIndex: "buyer_email",
      key: "buyer_email",
      render: (email) => (role === "admin" ? email : "N/A"),
    },
    {
      title: "Order Date",
      dataIndex: "order_date",
      key: "order_date",
      render: (date) => {
        if (!date) return "N/A";
        const formattedDate = new Date(date).toLocaleString("en-UK", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color =
          status === "completed"
            ? "green"
            : status === "pending"
            ? "orange"
            : "red";
        return (
          <Tag color={color}>
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, order) => (
        <Button
          type="link"
          icon={<SettingOutlined />}
          onClick={() => handleOpenModal(order)}
        >
          Manage Order
        </Button>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px", marginTop: "20px" }}>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#F3E5F5", borderColor: "#AB47BC" }}>
            <Statistic
              title="Total Orders"
              value={totalOrders}
              valueStyle={{ color: "#6A1B9A" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#E1F5FE", borderColor: "#64B5F6" }}>
            <Statistic
              title="Pending Orders"
              value={pendingOrders}
              valueStyle={{ color: "#1890ff" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#E8F5E9", borderColor: "#66BB6A" }}>
            <Statistic
              title="Completed Orders"
              value={completedOrders}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#FFEBEE", borderColor: "#E57373" }}>
            <Statistic
              title="Canceled Orders"
              value={canceledOrders}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
        </Col>
      </Row>

      {errorMessage && (
        <Alert
          message={errorMessage}
          type="error"
          showIcon
          style={{ marginBottom: "20px", textAlign: "center" }}
        />
      )}

      <Row gutter={16} className="mb-4">
        <Col span={24}>
          <Card
            title="Daily Orders Overview"
            style={{ backgroundColor: "#E3F2FD", borderColor: "#64B5F6" }}
          >
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={filteredGraphData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#B3E5FC" />
                <XAxis dataKey="date" stroke="#0277BD" />
                <YAxis stroke="#0277BD" />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="count" stroke="#8884d8" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={12} md={6}>
          <Input
            placeholder="Search by Order ID, Website Name, or Status"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col xs={24} sm={12} md={6}>
          <DatePicker
            onChange={onDateChange}
            value={selectedDate}
            placeholder="Select Date"
            allowClear
            style={{ width: "100%" }}
          />
        </Col>
      </Row>

      <Table
        dataSource={currentOrders}
        columns={columns}
        rowKey="id"
        pagination={false}
        scroll={{ x: "max-content" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Pagination
          current={currentPage}
          pageSize={ordersPerPage}
          total={filteredOrders.length}
          onChange={paginate}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Items per page:</span>
          <Select
            value={ordersPerPage}
            style={{ width: 80 }}
            onChange={handleOrdersPerPageChange}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
      </div>

      <Modal
        title={`Manage Order #${selectedOrder ? selectedOrder.id : ""}`}
        open={showModal}
        onCancel={handleCloseModal}
        footer={[
          <Button
            key="cancel"
            type="link"
            icon={<CloseCircleFilled />}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>,
          <Button
            key="reject"
            type="link"
            icon={<MinusCircleFilled />}
            onClick={() => handleUpdateStatus("canceled")}
            danger
          >
            Mark as Canceled
          </Button>,
          <Button
            key="complete"
            type="link"
            icon={<CheckCircleFilled />}
            onClick={() => handleUpdateStatus("completed")}
          >
            Mark as Completed
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Special Requirements">
            <Input.TextArea
              rows={4}
              value={specialRequirements}
              onChange={(e) => setSpecialRequirements(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default SellerOrders;

import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>WriteShuttle Marketplace - Buy and Sell Products Worldwide</title>
                <meta name="description" content="Your journey to seamless transactions starts here. Explore a wide range of products from verified sellers worldwide on WriteShuttle Marketplace." />
                <meta name="keywords" content="marketplace, buy products, sell products, ecommerce platform, online shopping, verified sellers" />
                <meta name="author" content="WriteShuttle" />

                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="WriteShuttle Marketplace" />
                <meta property="og:description" content="Join WriteShuttle to buy and sell products globally. Explore our marketplace for diverse products and seamless transactions." />
                <meta property="og:image" content="https://writeshuttle.com/logo192.png" />
                <meta property="og:url" content="https://www.writeshuttle.com" />
                <meta property="og:type" content="website" />

                {/* Twitter Card Meta Tags */}
                <meta name="twitter:title" content="WriteShuttle Marketplace" />
                <meta name="twitter:description" content="A platform to buy and sell products worldwide. Join now!" />
                <meta name="twitter:image" content="https://writeshuttle.com/logo192.png" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>

            {/* Full-Width Header with Navigation and Auth Buttons */}
            <header className="navbar">
                <nav>
                    <ul className="nav-links">
                        <li><a href="#intro">Home</a></li>
                        <li><a href="#buyer">Become a Buyer</a></li>
                        <li><a href="#seller">Become a Seller</a></li>
                        <li><a href="#reviews">Reviews</a></li>
                        <li><a href="#footer">Contact</a></li>
                    </ul>
                    <div className="auth-links">
                        <a href="/login" className="auth-btn">Login</a>
                        <a href="/register" className="auth-btn">Create Account</a>
                    </div>
                </nav>
            </header>

            {/* Intro Section */}
            <section id="intro" className="section intro">
                <div className="intro-content">
                    <motion.h1
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.8, ease: 'easeOut' }}
                    >
                        Welcome to Our Marketplace
                    </motion.h1>
                    <p>Your journey to seamless transactions starts here.</p>
                    <button
                        type="button"
                        className="cta-button"
                        onClick={() => {
                            document.getElementById('buyer').scrollIntoView({ behavior: 'smooth' });
                        }}
                    >
                        Explore Now
                    </button>
                </div>
            </section>

            {/* Become a Buyer Section */}
            <section id="buyer" className="section buyer">
                <motion.div
                    className="section-content"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: 'easeOut' }}
                >
                    <h2>Become a Buyer</h2>
                    <p>
                        Discover an array of products from verified sellers worldwide. Sign up today to start purchasing.
                    </p>
                    <a href="/register" className="cta-button">Join as Buyer</a>
                </motion.div>
            </section>

            {/* Become a Seller Section */}
            <section id="seller" className="section seller">
                <motion.div
                    className="section-content"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: 'easeOut' }}
                >
                    <h2>Become a Seller</h2>
                    <p>
                        Ready to reach thousands of customers? Register as a seller and showcase your products to the world.
                    </p>
                    <a href="/register" className="cta-button">Join as Seller</a>
                </motion.div>
            </section>

            {/* Reviews Section */}
            <section id="reviews" className="section reviews">
                <motion.div
                    className="section-content"
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, ease: 'easeOut' }}
                >
                    <h2>Client Reviews</h2>
                    <div className="review">
                        <p>"Amazing platform! Seamless buying experience."</p>
                        <span>- Client A</span>
                    </div>
                    <div className="review">
                        <p>"I was able to sell my products easily. Great support!"</p>
                        <span>- Client B</span>
                    </div>
                </motion.div>
            </section>

            {/* Footer */}
            <footer id="footer" className="footer">
                <div className="container">
                    <ul className="footer-links">
                        <li>
                            <button type="button" className="link-button">
                                Privacy Policy
                            </button>
                        </li>
                        <li>
                            <button type="button" className="link-button">
                                Social Media
                            </button>
                        </li>
                        <li>
                            <button type="button" className="link-button">
                                Location
                            </button>
                        </li>
                    </ul>
                    <p>&copy; 2024 Your Company. All rights reserved.</p>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;

import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDashboardData, deleteWebsite } from "../actions/dashboardActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Table,
  Tag,
  Alert,
  Pagination,
  Button,
  Modal,
  Form,
  Input,
  
  Row,
  Col,
  Select,
  InputNumber,
  DatePicker,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  ShoppingCartOutlined,
  CloseCircleFilled,
  CheckCircleFilled,
} from "@ant-design/icons";
const { RangePicker } = DatePicker;
const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { websites, role } = useSelector((state) => state.dashboard);
  const userId =
    useSelector((state) => state.dashboard.userId) ||
    sessionStorage.getItem("user_id");

  const [orderMessage, setOrderMessage] = useState("");
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    websiteId: null,
    requirements: "",
  });
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [pageSize, setPageSize] = useState(5);

  ////sreach system
  // Filter states with default values
  const [filterDa, setFilterDa] = useState([0, 100]);
  const [filterDr, setFilterDr] = useState([0, 100]);
  const [filterPrice, setFilterPrice] = useState([0, 10000]);
  const [filterTraffic, setFilterTraffic] = useState([0, 10000000]);
  const [filterLanguage, setFilterLanguage] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterCountry, setFilterCountry] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterDateRange, setFilterDateRange] = useState([null, null]);
  // State to hold unique options for each filter
  const [languageOptions, setLanguageOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  // State for zoom modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [zoomedScreenshot, setZoomedScreenshot] = useState("");
  useEffect(() => {
    // Extract unique values for Country, Language, and Category filters
    const uniqueLanguages = new Set();
    const uniqueCategories = new Set();
    const uniqueCountries = new Set();

    websites.forEach((site) => {
      site.language_names.forEach((language) =>
        uniqueLanguages.add(language.name)
      );
      site.category_names.forEach((category) =>
        uniqueCategories.add(category.name)
      );
      site.country_names.forEach((country) =>
        uniqueCountries.add(country.name)
      );
    });

    setLanguageOptions([...uniqueLanguages]);
    setCategoryOptions([...uniqueCategories]);
    setCountryOptions([...uniqueCountries]);
  }, [websites]);
  // Reset all filters
  const resetFilters = () => {
    setFilterDa([0, 100]);
    setFilterDr([0, 100]);
    setFilterPrice([0, 10000]);
    setFilterTraffic([0, 10000000]);
    setFilterLanguage([]);
    setFilterCategory([]);
    setFilterCountry([]);
    setFilterName("");
    setFilterId("");
    setFilterDateRange([null, null]); // Reset the date range here
  };
  const sortedWebsites = [...websites].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  // Filtered websites based on selected criteria
  const filteredWebsitess = sortedWebsites.filter((site) => {
    const daMatch =
      (filterDa[0] === 0 && filterDa[1] === 100) ||
      (site.domain_authority >= filterDa[0] &&
        site.domain_authority <= filterDa[1]);
    const drMatch =
      (filterDr[0] === 0 && filterDr[1] === 100) ||
      (site.domain_rating >= filterDr[0] && site.domain_rating <= filterDr[1]);
    const priceMatch =
      (filterPrice[0] === 0 && filterPrice[1] === 10000) ||
      (site.price >= filterPrice[0] && site.price <= filterPrice[1]);
    const trafficMatch =
      (filterTraffic[0] === 0 && filterTraffic[1] === 10000000) ||
      (site.monthly_traffic >= filterTraffic[0] &&
        site.monthly_traffic <= filterTraffic[1]);

    const languageMatch =
      filterLanguage.length === 0 ||
      filterLanguage.some((lang) =>
        site.language_names.some((language) => language.name === lang)
      );
    const categoryMatch =
      filterCategory.length === 0 ||
      filterCategory.some((cat) =>
        site.category_names.some((category) => category.name === cat)
      );
    const countryMatch =
      filterCountry.length === 0 ||
      filterCountry.some((cntry) =>
        site.country_names.some((country) => country.name === cntry)
      );

    const nameMatch =
      filterName === "" ||
      site.name.toLowerCase().includes(filterName.toLowerCase());
    const idMatch = filterId === "" || site.id.toString() === filterId;
    const dateMatch =
      !filterDateRange[0] ||
      (new Date(site.created_at) >= new Date(filterDateRange[0]) &&
        new Date(site.created_at) <= new Date(filterDateRange[1]));

    return (
      daMatch &&
      drMatch &&
      priceMatch &&
      trafficMatch &&
      languageMatch &&
      categoryMatch &&
      countryMatch &&
      nameMatch &&
      idMatch &&
      dateMatch
    );
  });
  // Fetch data on mount
  useEffect(() => {
    dispatch(fetchDashboardData());
  }, [dispatch]);
  useEffect(() => {
    console.log("API Response for websites:", websites); // Log the websites data
  }, [websites]);
  useEffect(() => {
    const storedPage = localStorage.getItem("currentPage");
    if (!storedPage) {
      setCurrentPage(1); // Set default to 1 if nothing is saved in localStorage
    }
  }, []);

  useEffect(() => {
    const storedRole = sessionStorage.getItem("storedRole");
    if (role && role !== "N/A" && storedRole !== role) {
      toast.success(`Welcome ${role}!`, { autoClose: 2000 });
      sessionStorage.setItem("storedRole", role);
    }
  }, [role]);

  // Define hooks before any conditional return
  const handleEditWebsite = useCallback(
    (websiteId) => {
      navigate(`/dashboard/edit-website/${websiteId}`);
    },
    [navigate]
  );

  const handleDeleteWebsite = useCallback(
    (websiteId) => {
      if (window.confirm("Are you sure you want to delete this website?")) {
        dispatch(deleteWebsite(websiteId));
      }
    },
    [dispatch]
  );
  
  const filteredWebsites = React.useMemo(() => {
    if (role === "seller") {
      // If role is seller, filter to only show websites owned by this seller
      return websites.filter((site) => site.seller_id === parseInt(userId));
    }
    if (role === "buyer") {
      // If role is buyer, filter to show only approved websites
      return websites.filter((site) => site.is_approved === true);
    }
    // Admin or other roles can see all websites
    return websites;
  }, [websites, role, userId]);

  const openOrderModal = (websiteId) => {
    setOrderDetails({ ...orderDetails, websiteId });
    setShowOrderModal(true);
  };

  const closeOrderModal = () => {
    setShowOrderModal(false);
  };

  const handleOrderSubmit = () => {
    const token = sessionStorage.getItem("token");

    axios
      .post(
        "https://writeshuttle.com/api/order/",
        {
          website_id: orderDetails.websiteId,
          special_requests: orderDetails.requirements,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then(() => {
        setOrderMessage(
          `Order placed successfully for website #${orderDetails.websiteId}`
        );
        setShowOrderModal(false);
        setOrderDetails({ websiteId: null, requirements: "" });

        setTimeout(() => {
          setOrderMessage("");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error placing order", error);
        const errorMessage =
          error.response?.data?.error || "Error placing order";
        setOrderMessage(errorMessage);
        toast.error(errorMessage); // Add a toast error message for better UX
        setTimeout(() => {
          setOrderMessage("");
        }, 2000);
      });
  };
  // Function to open the zoomed image in a modal
  const handleShowModal = (screenshot) => {
    setZoomedScreenshot(screenshot);
    setIsModalVisible(true); // Open modal
  };

  const handleCloseModal = () => {
    setIsModalVisible(false); // Close modal
  };
  const columns = [
    {
      title: "Screenshot",
      dataIndex: "screenshot",
      key: "screenshot",
      render: (screenshot) => (
        <Tooltip title="Click to view the image in full size" placement="top">
          <img
            src={`https://writeshuttle.com${screenshot}`} // Replace with actual server URL
            alt="Screenshot"
            style={{
              width: "100px",
              height: "auto",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => handleShowModal(screenshot)} // Show modal on click
          />
        </Tooltip>
      ),
    },
    {
      title: "Website Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <h3 style={{ fontFamily: "SF Pro" }}>
          {text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
        </h3>
      ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ), // Link to URL
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => (
        <Tag color="red" style={{ fontWeight: "bold" }}>
          ${price}
        </Tag>
      ),
    }
,       
   ...(role === "admin" 
    ? [
        {
          title: "Seller ID",
          dataIndex: "seller_id",
          key: "seller_id",
        },
        {
          title: "Seller Email",
          dataIndex: "seller_email",
          key: "seller_email",
        },
      ]
    : []),{
      title: "Monthly Traffic",
      dataIndex: "monthly_traffic",
      key: "monthly_traffic",
    },
    {
      title: "Domain Authority",
      dataIndex: "domain_authority",
      key: "domain_authority",
    },
    {
      title: "Domain Rating",
      dataIndex: "domain_rating",
      key: "domain_rating",
    },
    {
      title: "Page Authority",
      dataIndex: "page_authority",
      key: "page_authority",
    },
    {
      title: "Link Type",
      dataIndex: "link_type",
      key: "link_type",
    },
    {
      title: "Country",
      dataIndex: "country_names",
      key: "country_names",
      render: (country_names) =>
        country_names.map((country) => (
          <Tag color="blue" key={country.id} style={{ marginBottom: 4 }}>
            {country.name}
          </Tag>
        )),
    },
    {
      title: "Language",
      dataIndex: "language_names",
      key: "language_names",
      render: (language_names) =>
        language_names.map((language) => (
          <Tag color="green" key={language.id} style={{ marginBottom: 4 }}>
            {language.name}
          </Tag>
        )),
    },
    {
      title: "Category",
      dataIndex: "category_names",
      key: "category_names",
      render: (category_names) =>
        category_names.map((category) => (
          <Tag color="purple" key={category.id} style={{ marginBottom: 4 }}>
            {category.name}
          </Tag>
        )),
    },
    
    {
      title: "Number of Words",
      dataIndex: "number_of_words",
      key: "number_of_words",
    },
    
   
    ...(role === "admin" || role === "seller"
      ? [
    {
      title: "Status",
      dataIndex: "is_approved",
      key: "is_approved",
      render: (isApproved, record) => (
        <>
          {isApproved ? (
            <Tag color="green">Approved</Tag>
          ) : record.is_rejected ? (
            <Tag color="red">Rejected</Tag>
          ) : (
            <Tag color="orange">Pending</Tag> // Corrected missing quotation mark
          )}
        </>
      ),
    },
    ]
      : []),
    ...(role === "admin" 
      ? [
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const formattedDate = new Date(created_at).toLocaleString("en-UK", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return <span>{formattedDate}</span>;
      },
    },
    ]
      : []),
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          {role === "admin" && (
            <>
              <Button
                type="link"
                icon={<EditOutlined />}
                onClick={() => handleEditWebsite(record.id)}
              />
              <Button
                type="link"
                icon={<DeleteOutlined />}
                onClick={() => handleDeleteWebsite(record.id)}
              />
            </>
          )}
          {role === "seller" && record.seller_id === parseInt(userId) && (
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => handleEditWebsite(record.id)}
            />
          )}
          {role === "buyer" && (
            <Button
              type="link"
              icon={<ShoppingCartOutlined />}
              onClick={() => openOrderModal(record.id)}
            >
              Place Order
            </Button>
          )}
        </span>
      ),
    },
  ];

  const handleTableChange = (page) => {
    setCurrentPage(page);
    localStorage.setItem("currentPage", page); // Store page number in localStorage
  };

  return (
    <div style={{ padding: "5px", marginTop: "2px" }}>
      
      
      <Row gutter={[16, 16]} style={{ marginBottom: "20px", flexWrap: "wrap" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={12} sm={8} md={4} lg={3}>
            <label>DA Range:</label>
            <InputNumber
              min={0}
              max={100}
              value={filterDa[0]}
              onChange={(value) => setFilterDa([value, filterDa[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={100}
              value={filterDa[1]}
              onChange={(value) => setFilterDa([filterDa[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>DR Range:</label>
            <InputNumber
              min={0}
              max={100}
              value={filterDr[0]}
              onChange={(value) => setFilterDr([value, filterDr[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={100}
              value={filterDr[1]}
              onChange={(value) => setFilterDr([filterDr[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Price Range:</label>
            <InputNumber
              min={0}
              max={10000}
              value={filterPrice[0]}
              onChange={(value) => setFilterPrice([value, filterPrice[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={10000}
              value={filterPrice[1]}
              onChange={(value) => setFilterPrice([filterPrice[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Traffic Range:</label>
            <InputNumber
              min={0}
              max={10000000}
              value={filterTraffic[0]}
              onChange={(value) => setFilterTraffic([value, filterTraffic[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={10000000}
              value={filterTraffic[1]}
              onChange={(value) => setFilterTraffic([filterTraffic[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Name:</label>
            <Input
              placeholder="Search by name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>ID:</label>
            <Input
              placeholder="Search by ID"
              value={filterId}
              onChange={(e) => setFilterId(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Language:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select languages"
              value={filterLanguage}
              onChange={setFilterLanguage}
              style={{ width: "100%" }}
              size="small"
            >
              {languageOptions.map((lang) => (
                <Select.Option key={lang} value={lang}>
                  {lang}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Category:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select categories"
              value={filterCategory}
              onChange={setFilterCategory}
              style={{ width: "100%" }}
              size="small"
            >
              {categoryOptions.map((cat) => (
                <Select.Option key={cat} value={cat}>
                  {cat}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Country:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select countries"
              value={filterCountry}
              onChange={setFilterCountry}
              style={{ width: "100%" }}
              size="small"
            >
              {countryOptions.map((cntry) => (
                <Select.Option key={cntry} value={cntry}>
                  {cntry}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Date Range:</label>
            <RangePicker
              onChange={(dates) => {
                if (dates) {
                  setFilterDateRange(dates);
                } else {
                  setFilterDateRange([null, null]);
                }
              }}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <Button
              onClick={resetFilters}
              style={{ marginTop: "22px", width: "100%" }}
              size="small"
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Row>

      {orderMessage && (
        <Alert
          message="Success"
          description={orderMessage}
          type="success"
          showIcon
          style={{ marginBottom: "20px" }}
          closable
        />
      )}
      <Table
        columns={columns}
        dataSource={filteredWebsitess.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )}
        rowKey="id"
        pagination={false}
        style={{ overflowX: "auto" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredWebsites.length}
          onChange={handleTableChange}
        />
        <Modal
          open={isModalVisible}
          onCancel={handleCloseModal}
          footer={null}
          width="50%" // Modal takes up only 50% of the screen width
          centered
        >
          <img
            src={`https://writeshuttle.com${zoomedScreenshot}`} // Full-size image source
            alt="Zoomed Screenshot"
            style={{
              width: "90%", // Image width reduced to 90% of the modal's width
              maxHeight: "50vh", // Limit the height to 50% of the viewport height
              objectFit: "contain", // Maintain aspect ratio
              margin: "0 auto", // Center the image horizontally within the modal
              display: "block", // Ensure the image is displayed as a block-level element
            }}
          />
        </Modal>

        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Items per page:</span>
          <Select
            value={pageSize}
            style={{ width: 80 }}
            onChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
      </div>

      <Modal
        title="Place Order"
        open={showOrderModal}
        onCancel={closeOrderModal}
        footer={[
          <Button
            key="back"
            icon={<CloseCircleFilled />}
            type="link"
            onClick={closeOrderModal}
          >
            Close
          </Button>,
          <Button
            key="submit"
            icon={<CheckCircleFilled />}
            type="link"
            onClick={handleOrderSubmit}
          >
            Submit Order
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Additional Requirements">
            <Input.TextArea
              value={orderDetails.requirements}
              onChange={(e) =>
                setOrderDetails({
                  ...orderDetails,
                  requirements: e.target.value,
                })
              }
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Dashboard;

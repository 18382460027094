import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Input, Button, Card, Checkbox, Row, Col, Select } from "antd";
import api from "../api/api"; // Import the existing API instance
import { addWebsite } from "../actions/addwebsiteActions";

const { Option } = Select;

const LINK_TYPE_OPTIONS = [
  { value: "internal", label: "Internal" },
  { value: "external", label: "External" },
  { value: "inbound", label: "Inbound" },
  { value: "nofollow", label: "Nofollow" },
  { value: "dofollow", label: "Dofollow" },
  { value: "sponsored", label: "Sponsored" },
  { value: "ugc", label: "UGC" },
  { value: "image", label: "Image" },
  { value: "anchor_text", label: "Anchor Text" },
];

const AddWebsite = () => {
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [price, setPrice] = useState("");
  const [monthlyTraffic, setMonthlyTraffic] = useState("");
  const [linkType, setLinkType] = useState("");
  const [numberOfWords, setNumberOfWords] = useState("");
  const [domainRating, setDomainRating] = useState("");
  const [domainAuthority, setDomainAuthority] = useState("");
  const [pageAuthority, setPageAuthority] = useState("");
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for managing the form submission status

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.website);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryResponse = await api.get("/api/countries/", {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        });
        setCountries(countryResponse.data);

        const languageResponse = await api.get("/api/languages/", {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        });
        setLanguages(languageResponse.data);

        const categoryResponse = await api.get("/api/categories/", {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        });
        setCategories(categoryResponse.data);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
    fetchData();
  }, []);

  const handleCountryChange = (checkedValues) => {
    setSelectedCountries(checkedValues);
  };

  const handleLanguageChange = (checkedValues) => {
    setSelectedLanguages(checkedValues);
  };

  const handleCategoryChange = (checkedValues) => {
    setSelectedCategories(checkedValues);
  };

  // Helper function to enforce non-negative values
  const handlePositiveIntegerChange = (setter) => (e) => {
    const value = parseInt(e.target.value, 10);
    setter(value >= 0 ? value : 0); // Ensure the value is non-negative
  };

  const handleSubmit = (values) => {
    if (isSubmitting) return; // Prevent form submission if already submitting

    setIsSubmitting(true); // Set submitting flag
    toast.info("Please wait, capturing screenshot...", { autoClose: 5000 });

    dispatch(
      addWebsite(
        values.name,
        values.url,
        values.price,
        selectedCountries,
        selectedLanguages,
        selectedCategories,
        values.monthlyTraffic,
        values.linkType,
        values.numberOfWords,
        values.domainRating,
        values.domainAuthority,
        values.pageAuthority,
        description
      )
    )
      .then(() => {
        toast.success("Website added successfully!", { autoClose: 2000 });
        navigate("/dashboard/my-websites");
      })
      .catch(() => {
        toast.error("Error adding website. Please try again.", {
          autoClose: 2000,
        });
      })
      .finally(() => {
        setIsSubmitting(false); // Reset submitting flag after request is complete
      });
  };

  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100 bg-light">
      <Card
        title="Add New Website"
        className="shadow"
        style={{ maxWidth: "800px", width: "100%" }}
      >
        {error && <div className="alert alert-danger">{error}</div>}

        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Website Name"
                name="name"
                rules={[{ required: true, message: "Please enter the website name" }]}
              >
                <Input
                  placeholder="Enter website name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: true, message: "Please enter the price" }]}
              >
                <Input
                  type="number"
                  placeholder="Enter price"
                  min="0"
                  value={price}
                  onChange={handlePositiveIntegerChange(setPrice)}
                />
              </Form.Item>

              <Form.Item label="Link Type" name="linkType" rules={[{ required: true, message: "Please select the link type" }]}>
                <Select
                  placeholder="Select link type"
                  value={linkType}
                  onChange={(value) => setLinkType(value)}
                >
                  {LINK_TYPE_OPTIONS.map(option => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label="Domain Rating (DR)" name="domainRating">
                <Input
                  type="number"
                  placeholder="Enter domain rating"
                  min="0"
                  value={domainRating}
                  onChange={handlePositiveIntegerChange(setDomainRating)}
                />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[{ required: true, message: "Please enter a description" }]}
              >
                <Input.TextArea
                  placeholder="Enter website description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label="Website URL"
                name="url"
                rules={[
                  { required: true, message: "Please enter a valid URL" },
                  { type: "url", message: "Please enter a valid URL" },
                ]}
              >
                <Input
                  placeholder="https://example.com"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </Form.Item>

              <Form.Item label="Monthly Traffic" name="monthlyTraffic">
                <Input
                  type="number"
                  placeholder="Enter monthly traffic"
                  min="0"
                  value={monthlyTraffic}
                  onChange={handlePositiveIntegerChange(setMonthlyTraffic)}
                />
              </Form.Item>

              <Form.Item label="Number of Words" name="numberOfWords">
                <Input
                  type="number"
                  placeholder="Enter number of words"
                  min="0"
                  value={numberOfWords}
                  onChange={handlePositiveIntegerChange(setNumberOfWords)}
                />
              </Form.Item>

              <Form.Item label="Domain Authority (DA)" name="domainAuthority">
                <Input
                  type="number"
                  placeholder="Enter domain authority"
                  min="0"
                  value={domainAuthority}
                  onChange={handlePositiveIntegerChange(setDomainAuthority)}
                />
              </Form.Item>

              <Form.Item label="Page Authority (PA)" name="pageAuthority">
                <Input
                  type="number"
                  placeholder="Enter page authority"
                  min="0"
                  value={pageAuthority}
                  onChange={handlePositiveIntegerChange(setPageAuthority)}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Available in Countries">
            <Checkbox.Group
              options={countries.map((country) => ({
                label: country.name,
                value: country.id,
              }))}
              onChange={handleCountryChange}
            />
          </Form.Item>

          <Form.Item label="Available Languages">
            <Checkbox.Group
              options={languages.map((language) => ({
                label: language.name,
                value: language.id,
              }))}
              onChange={handleLanguageChange}
            />
          </Form.Item>

          <Form.Item label="Categories">
            <Checkbox.Group
              options={categories.map((category) => ({
                label: category.name,
                value: category.id,
              }))}
              onChange={handleCategoryChange}
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSubmitting || loading} // Disable button while submitting
              style={{ width: "100%" }}
            >
              Add Website
            </Button>
          </Form.Item>
        </Form>
      </Card>

      <ToastContainer />
    </div>
  );
};

export default AddWebsite;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { login as loginAction } from '../actions/authActions';

const Login = ({ login }) => {
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const { email, password } = formData;
    const navigate = useNavigate();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        const success = await login(email, password);
        if (success) {
            navigate('/dashboard');
        } else {
            setError('Incorrect email or password. Please try again.');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <div
            style={{
                background: 'linear-gradient(90deg, #6a11cb 0%, #2575fc 100%)',
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem',
                width: '100%',
            }}
        >
            <div className="col-md-5 col-lg-4">
                <div
                    className="card shadow-sm border-0"
                    style={{
                        borderRadius: '15px', // Curved border for the card
                    }}
                >
                    <div className="card-body p-4">
                        <h3 className="card-title mb-4 text-center" style={{ color: '#2575fc' }}>Login</h3>

                        {/* Display error message */}
                        {error && <div className="alert alert-danger text-center">{error}</div>}

                        <form onSubmit={onSubmit}>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={onChange}
                                    className="form-control form-control-lg"
                                    required
                                    style={{
                                        backgroundColor: '#f0f4ff',
                                        color: '#333',
                                        borderRadius: '30px', // Curved border for input
                                    }}
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Password</label>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    name="password"
                                    value={password}
                                    onChange={onChange}
                                    className="form-control form-control-lg"
                                    required
                                    style={{
                                        backgroundColor: '#f0f4ff',
                                        color: '#333',
                                        borderRadius: '30px', // Curved border for input
                                    }}
                                />
                            </div>

                            {/* Toggle Switch for Show Password */}
                            <div className="form-check mb-3 d-flex align-items-center">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="showPassword"
                                    checked={showPassword}
                                    onChange={togglePasswordVisibility}
                                    style={{
                                        appearance: 'none',
                                        width: '40px',
                                        height: '20px',
                                        backgroundColor: showPassword ? '#2575fc' : '#ccc',
                                        borderRadius: '30px',
                                        position: 'relative',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                />
                                <label
                                    className="form-check-label ms-2"
                                    htmlFor="showPassword"
                                    style={{ cursor: 'pointer', fontSize: '1rem', color: '#333' }}
                                >
                                    Show Password
                                </label>
                                {/* Toggle handle (slider circle) */}
                                <div
                                    style={{
                                        position: 'absolute',
                                        width: '18px',
                                        height: '18px',
                                        borderRadius: '50%',
                                        backgroundColor: '#fff',
                                        top: '1px',
                                        left: showPassword ? '20px' : '2px',
                                        transition: 'left 0.3s ease',
                                    }}
                                />
                            </div>

                            <button
                                type="submit"
                                className="btn btn-primary btn-lg w-100 mb-3"
                                style={{
                                    backgroundColor: '#2575fc',
                                    borderColor: '#2575fc',
                                    fontWeight: '500',
                                    borderRadius: '30px', // Curved border for button
                                }}
                            >
                                Login
                            </button>
                        </form>

                        {/* Register link */}
                        <div className="text-center">
                            <span>Don't have an account? </span>
                            <Link to="/register" className="text-decoration-none" style={{ color: '#2575fc' }}>
                                Register here
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { login: loginAction })(Login);

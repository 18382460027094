import React, { useState, useEffect } from "react";
import axios from "../api/api";
import {
  Table,
  Modal,
  Button,
  Tag,
  Space,
  Card,
  Row,
  Col,
  Statistic,
  notification,
  Typography,
  Divider,
  Input,
  Select,
  Pagination,
} from "antd";
import {
  InfoCircleFilled,
  CloseCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";

const { Paragraph, Text, Title } = Typography;

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [websites, setWebsites] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [pageSize, setPageSize] = useState(5); 

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    const maxPage = Math.ceil(filteredUsers.length / pageSize);
    if (currentPage > maxPage && maxPage > 0) {
      setCurrentPage(maxPage);
    }
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage, filteredUsers.length, pageSize]);

  const fetchUsers = () => {
    axios
      .get("/api/users/", {
        headers: { Authorization: `Token ${sessionStorage.getItem("token")}` },
      })
      .then((res) => {
        const filteredUsers = res.data
          .filter((user) => user.type !== "admin")
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        setUsers(filteredUsers);
        setFilteredUsers(filteredUsers);
        notification.success({
          message: "Users Loaded",
          description: "User data loaded successfully.",
          duration: 2,
        });
      })
      .catch((err) => {
        console.error("Failed to fetch users:", err);
        notification.error({
          message: "Error",
          description: "Failed to load user data. Please try again.",
          duration: 2,
        });
      });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filteredData = users.filter((user, index) => {
      const no = users.length - index;
      return (
        user.id.toString().includes(value) ||
        (user.is_seller ? "seller" : user.is_buyer ? "buyer" : "unknown")
          .toLowerCase()
          .includes(value) ||
        user.email.toLowerCase().includes(value) ||
        no.toString().includes(value) ||
        new Date(user.created_at)
          .toLocaleDateString("en-UK", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          })
          .includes(value)
      );
    });
    setFilteredUsers(filteredData);
  };

  const handleViewSellerDetails = (user) => {
    setSelectedUser(user);
    setShowModal(true);

    if (user.is_seller) {
      axios
        .get(`/api/websites/?seller=${user.id}`, {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setWebsites(res.data);
          notification.success({
            message: "Websites Loaded",
            description: `Websites for seller ${user.email} loaded successfully.`,
            duration: 2,
          });
        })
        .catch((err) => {
          console.error("Failed to fetch websites for seller:", err);
          notification.error({
            message: "Error",
            description: "Failed to load websites for the seller.",
            duration: 2,
          });
        });
    } else {
      setWebsites([]);
    }
  };

  const handleToggleActiveStatus = (userId, isActive) => {
    axios
      .put(
        `/api/users/${userId}/`,
        { is_active: !isActive },
        {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        const updatedUsers = users.map((user) =>
          user.id === userId ? { ...user, is_active: !isActive } : user
        );
        setUsers(updatedUsers);
        setFilteredUsers(
          updatedUsers.filter(
            (user) =>
              user.id.toString().includes(searchText) ||
              user.email.toLowerCase().includes(searchText)
          )
        );
        notification.success({
          message: "User Status Updated",
          description: `User has been ${
            !isActive ? "activated" : "deactivated"
          } successfully.`,
          duration: 2,
        });
      })
      .catch((err) => {
        console.error("Failed to update user status:", err);
        notification.error({
          message: "Error",
          description: "Failed to update user status. Please try again.",
          duration: 2,
        });
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setWebsites([]);
  };

  const totalUsers = users.length;
  const activeUsers = users.filter((user) => user.is_active).length;
  const inactiveUsers = totalUsers - activeUsers;
  const totalSellers = users.filter((user) => user.is_seller).length;
  const totalBuyers = users.filter((user) => user.is_buyer).length;

  const userColumns = [
    {
      title: "Serial No.",
      key: "index",
      render: (text, record, index) => filteredUsers.length - index,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "User ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Role",
      key: "role",
      render: (text, record) => (
        <>
          {record.is_seller ? "Seller" : record.is_buyer ? "Buyer" : "Unknown"}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <Tag color={record.is_active ? "green" : "red"}>
          {record.is_active ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A";
        const formattedDate = new Date(created_at).toLocaleString("en-UK", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<InfoCircleFilled />}
            onClick={() => handleViewSellerDetails(record)}
          >
            View Details
          </Button>
          <Button
            type="primary"
            danger={record.is_active}
            style={
              !record.is_active
                ? { backgroundColor: "#52c41a", borderColor: "#52c41a" }
                : {}
            }
            onClick={() =>
              handleToggleActiveStatus(record.id, record.is_active)
            }
          >
            {record.is_active ? "Deactivate" : "Activate"}
          </Button>
        </Space>
      ),
    },
  ];

  const currentUsers = filteredUsers.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  return (
    <div className="container mt-3">
      <Row gutter={16} className="mb-4">
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#F3E5F5", borderColor: "#AB47BC" }}>
            <Statistic
              title="Total Users"
              value={totalUsers}
              valueStyle={{ color: "#6A1B9A" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#E8F5E9", borderColor: "#66BB6A" }}>
            <Statistic
              title="Active Users"
              value={activeUsers}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#FFEBEE", borderColor: "#E57373" }}>
            <Statistic
              title="Inactive Users"
              value={inactiveUsers}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#FFF3E0", borderColor: "#FFB74D" }}>
            <Statistic
              title="Total Sellers"
              value={totalSellers}
              valueStyle={{ color: "#FB8C00" }}
            />
          </Card>
        </Col>
        <Col xs={12} sm={8} md={6}>
          <Card style={{ backgroundColor: "#E1F5FE", borderColor: "#29B6F6" }}>
            <Statistic
              title="Total Buyers"
              value={totalBuyers}
              valueStyle={{ color: "#0277BD" }}
            />
          </Card>
        </Col>
      </Row>

      <Input
        placeholder="Search by ID, Email, Role, No., or Date"
        prefix={<SearchOutlined />}
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: "16px", width: "100%", maxWidth: "400px" }}
      />

      <Table
        columns={userColumns}
        dataSource={currentUsers}
        rowKey="id"
        pagination={false}
        style={{ overflowX: "auto" }}
      />

      <div
        className="pagination-controls"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredUsers.length}
          onChange={(page) => setCurrentPage(page)}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Items per page:</span>
          <Select
            value={pageSize}
            style={{ width: 80 }}
            onChange={(value) => {
              setPageSize(value);
              setCurrentPage(1);
            }}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
      </div>

      <Modal
        title={<Title level={4} style={{ margin: 0 }}>User Details</Title>}
        open={showModal}
        onCancel={handleCloseModal}
        footer={[
          <Button
            key="close"
            type="link"
            icon={<CloseCircleFilled />}
            onClick={handleCloseModal}
            style={{ borderRadius: "5px" }}
          >
            Close
          </Button>,
        ]}
        style={{
          padding: "20px",
          backgroundColor: "#f7f9fc",
          borderRadius: "8px",
        }}
        centered
      >
        {selectedUser && (
          <>
            <Paragraph>
              <Text strong>Email:</Text> {selectedUser.email}
            </Paragraph>
            <Paragraph>
              <Text strong>Role:</Text>{" "}
              {selectedUser.is_seller
                ? "Seller"
                : selectedUser.is_buyer
                ? "Buyer"
                : "Unknown"}
            </Paragraph>
            <Paragraph>
              <Text strong>Status:</Text>{" "}
              <span
                style={{
                  color: selectedUser.is_active ? "#3f8600" : "#cf1322",
                }}
              >
                {selectedUser.is_active ? "Active" : "Inactive"}
              </span>
            </Paragraph>

            {selectedUser.is_seller && (
              <>
                <Divider style={{ margin: "16px 0" }} />
                <Title level={5} style={{ margin: "16px 0" }}>
                  Websites Owned by Seller
                </Title>
                <Table
                  columns={[
                    {
                      title: "Website Name",
                      dataIndex: "name",
                      key: "name",
                    },
                    {
                      title: "Price",
                      dataIndex: "price",
                      key: "price",
                      render: (price) => `$${price}`,
                    },
                    {
                      title: "Date Added",
                      dataIndex: "created_at",
                      key: "created_at",
                      render: (created_at) => {
                        if (!created_at) return "N/A";
                        const formattedDate = new Date(
                          created_at
                        ).toLocaleString("en-UK", {
                          month: "2-digit",
                          day: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          hour12: true,
                        });
                        return <span>{formattedDate}</span>;
                      },
                    },
                    {
                      title: "Status",
                      key: "status",
                      render: (text, record) => (
                        <Tag color={record.is_active ? "green" : "red"}>
                          {record.is_active ? "Active" : "Inactive"}
                        </Tag>
                      ),
                    },
                  ]}
                  dataSource={websites}
                  rowKey="id"
                  pagination={false}
                  bordered
                  style={{ backgroundColor: "#fff", borderRadius: "8px" }}
                />
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  );
};

export default ManageUsers;

// components/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ element: Element, allowedRoles }) => {
    const { token, isAuthenticated, role } = useSelector(state => state.auth);

    if (!token || !isAuthenticated) {
        // If not authenticated, redirect to login
        return <Navigate to="/login" replace />;
    }

    if (allowedRoles && !allowedRoles.includes(role)) {
        // If the user role is not allowed, redirect to the dashboard or access-denied page
        return <Navigate to="/dashboard" replace />;
    }

    // Render the component if authenticated and authorized
    return <Element />;
};

export default PrivateRoute;

import React from 'react';
import ReactDOM from 'react-dom/client'; // Import ReactDOM from 'react-dom/client' for React 18
import { Provider } from 'react-redux'; // Import Provider
import store from './store/store'; // Import your Redux store
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'antd/dist/reset.css';
// Create root
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}> {/* Wrap App with Provider */}
        <App />
    </Provider>
);

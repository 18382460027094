import axios from '../api/api';
import {
    ADD_WEBSITE_REQUEST,
    ADD_WEBSITE_SUCCESS,
    ADD_WEBSITE_FAILURE
} from '../constants/websiteActionTypes';

export const addWebsite = (
    name,
    url,
    price,
    countries,
    languages,
    categories,
    monthlyTraffic,
    linkType,
    numberOfWords,
    domainRating,
    domainAuthority,
    pageAuthority,
    description
) => {
    return async (dispatch) => {
        dispatch({ type: ADD_WEBSITE_REQUEST });

        
        const payload = {
            name,
            url,
            price: price ? parseFloat(price) : null, // Convert price to float
            countries,
            languages,
            categories,
            monthly_traffic: monthlyTraffic ? parseInt(monthlyTraffic, 10) : null,
            link_type: linkType,
            number_of_words: numberOfWords ? parseInt(numberOfWords, 10) : null,
            domain_rating: domainRating ? parseFloat(domainRating) : null, // Use float for decimal values
            domain_authority: domainAuthority ? parseFloat(domainAuthority) : null,
            page_authority: pageAuthority ? parseFloat(pageAuthority) : null,
            description
        };

       
        console.log("Full Payload:", payload);

        try {
            const response = await axios.post('/api/websites/add/', payload, {
                headers: { 'Authorization': `Token ${sessionStorage.getItem('token')}` }
            });
            dispatch({ type: ADD_WEBSITE_SUCCESS, payload: response.data });
        } catch (error) {
            // Log detailed error response from the server
            console.error("Error response:", error.response ? error.response.data : error.message);
            dispatch({
                type: ADD_WEBSITE_FAILURE,
                error: error.response ? error.response.data : error.message,
            });
        }
    };
};

// userActionTypes.js
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_SELLER_WEBSITES_REQUEST = 'FETCH_SELLER_WEBSITES_REQUEST';
export const FETCH_SELLER_WEBSITES_SUCCESS = 'FETCH_SELLER_WEBSITES_SUCCESS';
export const FETCH_SELLER_WEBSITES_FAILURE = 'FETCH_SELLER_WEBSITES_FAILURE';

export const TOGGLE_USER_STATUS_REQUEST = 'TOGGLE_USER_STATUS_REQUEST';
export const TOGGLE_USER_STATUS_SUCCESS = 'TOGGLE_USER_STATUS_SUCCESS';
export const TOGGLE_USER_STATUS_FAILURE = 'TOGGLE_USER_STATUS_FAILURE';

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchMyWebsites } from "../actions/myWebsitesActions";
import {
  Table,
  Tag,
  Alert,
  Row,
  Col,
  Card,
  Statistic,
  Modal,
  Button,
  Select,
  Pagination,
  InputNumber,
  Input,
  DatePicker,
} from "antd";
import { CloseCircleFilled } from "@ant-design/icons";

const MyWebsites = () => {
  const dispatch = useDispatch();
  const { websites, error } = useSelector((state) => state.myWebsites);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const { RangePicker } = DatePicker;
  // Filter states with default values
  const [filterDa, setFilterDa] = useState([0, 100]);
  const [filterDr, setFilterDr] = useState([0, 100]);
  const [filterPrice, setFilterPrice] = useState([0, 10000]);
  const [filterTraffic, setFilterTraffic] = useState([0, 10000000]);
  const [filterLanguage, setFilterLanguage] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterCountry, setFilterCountry] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterDateRange, setFilterDateRange] = useState([null, null]);
  // State to hold unique options for each filter
  const [languageOptions, setLanguageOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const userRole = sessionStorage.getItem("user_role");
  const userId = sessionStorage.getItem("user_id");

  useEffect(() => {
    if (userRole === "seller" || userRole === "admin") {
      dispatch(fetchMyWebsites(userId, userRole));
    }
  }, [dispatch, userRole, userId]);

  useEffect(() => {
    // Extract unique values for Country, Language, and Category filters
    const uniqueLanguages = new Set();
    const uniqueCategories = new Set();
    const uniqueCountries = new Set();

    websites.forEach((site) => {
      site.language_names.forEach((language) =>
        uniqueLanguages.add(language.name)
      );
      site.category_names.forEach((category) =>
        uniqueCategories.add(category.name)
      );
      site.country_names.forEach((country) =>
        uniqueCountries.add(country.name)
      );
    });

    setLanguageOptions([...uniqueLanguages]);
    setCategoryOptions([...uniqueCategories]);
    setCountryOptions([...uniqueCountries]);
  }, [websites]);

  useEffect(() => {
    const maxPage = Math.ceil(websites.length / pageSize);
    if (currentPage > maxPage && maxPage > 0) {
      setCurrentPage(maxPage);
    }
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage, websites.length, pageSize]);

  const showRejectionReason = (reason) => {
    setSelectedReason(reason);
    setIsModalVisible(true);
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  // Reset all filters
  const resetFilters = () => {
    setFilterDa([0, 100]);
    setFilterDr([0, 100]);
    setFilterPrice([0, 10000]);
    setFilterTraffic([0, 10000000]);
    setFilterLanguage([]);
    setFilterCategory([]);
    setFilterCountry([]);
    setFilterName("");
    setFilterId("");
    setFilterDateRange([null, null]); // Reset the date range here
  };
  const sortedWebsites = [...websites].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );
  // Filtered websites based on selected criteria
  const filteredWebsites = sortedWebsites.filter((site) => {
    const daMatch =
      (filterDa[0] === 0 && filterDa[1] === 100) ||
      (site.domain_authority >= filterDa[0] &&
        site.domain_authority <= filterDa[1]);
    const drMatch =
      (filterDr[0] === 0 && filterDr[1] === 100) ||
      (site.domain_rating >= filterDr[0] && site.domain_rating <= filterDr[1]);
    const priceMatch =
      (filterPrice[0] === 0 && filterPrice[1] === 10000) ||
      (site.price >= filterPrice[0] && site.price <= filterPrice[1]);
    const trafficMatch =
      (filterTraffic[0] === 0 && filterTraffic[1] === 10000000) ||
      (site.monthly_traffic >= filterTraffic[0] &&
        site.monthly_traffic <= filterTraffic[1]);

    const languageMatch =
      filterLanguage.length === 0 ||
      filterLanguage.some((lang) =>
        site.language_names.some((language) => language.name === lang)
      );
    const categoryMatch =
      filterCategory.length === 0 ||
      filterCategory.some((cat) =>
        site.category_names.some((category) => category.name === cat)
      );
    const countryMatch =
      filterCountry.length === 0 ||
      filterCountry.some((cntry) =>
        site.country_names.some((country) => country.name === cntry)
      );

    const nameMatch =
      filterName === "" ||
      site.name.toLowerCase().includes(filterName.toLowerCase());
    const idMatch = filterId === "" || site.id.toString() === filterId;
    const dateMatch =
      !filterDateRange[0] ||
      (new Date(site.created_at) >= new Date(filterDateRange[0]) &&
        new Date(site.created_at) <= new Date(filterDateRange[1]));

    return (
      daMatch &&
      drMatch &&
      priceMatch &&
      trafficMatch &&
      languageMatch &&
      categoryMatch &&
      countryMatch &&
      nameMatch &&
      idMatch &&
      dateMatch
    );
  });

  if (userRole !== "seller" && userRole !== "admin") {
    return (
      <div style={{ marginTop: "50px", textAlign: "center" }}>
        <p style={{ color: "#999" }}>
          Only sellers and admins can view websites. Please log in as a seller
          or admin to access this page.
        </p>
      </div>
    );
  }

  const columns = [
    {
      title: "Site No.",
      key: "index",
      render: (text, record, index) =>
        websites.length - ((currentPage - 1) * pageSize + index),
    },
    {
      title: "Website ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Website Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <h3 style={{ fontFamily: "SF Pro" }}>
          {text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
        </h3>
      ),
      
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (price) => `$${price}`,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => <span>{description}</span>,
      responsive: ["md"], // Hide on small screens
    },
    {
      title: "Monthly Traffic",
      dataIndex: "monthly_traffic",
      key: "monthly_traffic",
    },
    {
      title: "Link Type",
      dataIndex: "link_type",
      key: "link_type",
    },
    {
      title: "Number of Words",
      dataIndex: "number_of_words",
      key: "number_of_words",
      responsive: ["md"], // Hide on small screens
    },
    {
      title: "Domain Rating",
      dataIndex: "domain_rating",
      key: "domain_rating",
    },
    {
      title: "Domain Authority",
      dataIndex: "domain_authority",
      key: "domain_authority",
    },
    {
      title: "Page Authority",
      dataIndex: "page_authority",
      key: "page_authority",
    },
    {
      title: "Country",
      dataIndex: "country_names",
      key: "country_names",
      render: (country_names) =>
        country_names.map((country) => (
          <Tag color="blue" key={country.id} style={{ marginBottom: 4 }}>
            {country.name}
          </Tag>
        )),
    },
    {
      title: "Language",
      dataIndex: "language_names",
      key: "language_names",
      render: (language_names) =>
        language_names.map((language) => (
          <Tag color="green" key={language.id} style={{ marginBottom: 4 }}>
            {language.name}
          </Tag>
        )),
    },
    {
      title: "Category",
      dataIndex: "category_names",
      key: "category_names",
      render: (category_names) =>
        category_names.map((category) => (
          <Tag color="purple" key={category.id} style={{ marginBottom: 4 }}>
            {category.name}
          </Tag>
        )),
    },
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        if (!created_at) return "N/A";
        const formattedDate = new Date(created_at).toLocaleString("en-UK", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <>
          {record.is_approved ? (
            <Tag color="green">Approved</Tag>
          ) : record.is_rejected ? (
            <>
              <Tag color="red">Rejected</Tag>
              <Button
                type="link"
                onClick={() => showRejectionReason(record.rejection_reason)}
                style={{ padding: 0 }}
              >
                View Reason
              </Button>
            </>
          ) : (
            <Tag color="orange">Pending</Tag>
          )}
        </>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px", marginTop: "20px" }}>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              backgroundColor: "#E3F2FD",
              borderColor: "#64B5F6",
              textAlign: "center",
            }}
          >
            <Statistic
              title="Total Websites"
              value={websites.length}
              valueStyle={{ color: "#1976D2" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              backgroundColor: "#E8F5E9",
              borderColor: "#66BB6A",
              textAlign: "center",
            }}
          >
            <Statistic
              title="Approved Websites"
              value={websites.filter((w) => w.is_approved).length}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              backgroundColor: "#FFEBEE",
              borderColor: "#E57373",
              textAlign: "center",
            }}
          >
            <Statistic
              title="Rejected Websites"
              value={websites.filter((w) => w.is_rejected).length}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={6}>
          <Card
            style={{
              backgroundColor: "#FFFDE7",
              borderColor: "#FFEE58",
              textAlign: "center",
            }}
          >
            <Statistic
              title="Pending Websites"
              value={
                websites.length -
                websites.filter((w) => w.is_approved || w.is_rejected).length
              }
              valueStyle={{ color: "#FFB300" }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: "20px", flexWrap: "wrap" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={12} sm={8} md={4} lg={3}>
            <label>DA Range:</label>
            <InputNumber
              min={0}
              max={100}
              value={filterDa[0]}
              onChange={(value) => setFilterDa([value, filterDa[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={100}
              value={filterDa[1]}
              onChange={(value) => setFilterDa([filterDa[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>DR Range:</label>
            <InputNumber
              min={0}
              max={100}
              value={filterDr[0]}
              onChange={(value) => setFilterDr([value, filterDr[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={100}
              value={filterDr[1]}
              onChange={(value) => setFilterDr([filterDr[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Price Range:</label>
            <InputNumber
              min={0}
              max={10000}
              value={filterPrice[0]}
              onChange={(value) => setFilterPrice([value, filterPrice[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={10000}
              value={filterPrice[1]}
              onChange={(value) => setFilterPrice([filterPrice[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Traffic Range:</label>
            <InputNumber
              min={0}
              max={10000000}
              value={filterTraffic[0]}
              onChange={(value) => setFilterTraffic([value, filterTraffic[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={10000000}
              value={filterTraffic[1]}
              onChange={(value) => setFilterTraffic([filterTraffic[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Name:</label>
            <Input
              placeholder="Search by name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>ID:</label>
            <Input
              placeholder="Search by ID"
              value={filterId}
              onChange={(e) => setFilterId(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Language:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select languages"
              value={filterLanguage}
              onChange={setFilterLanguage}
              style={{ width: "100%" }}
              size="small"
            >
              {languageOptions.map((lang) => (
                <Select.Option key={lang} value={lang}>
                  {lang}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Category:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select categories"
              value={filterCategory}
              onChange={setFilterCategory}
              style={{ width: "100%" }}
              size="small"
            >
              {categoryOptions.map((cat) => (
                <Select.Option key={cat} value={cat}>
                  {cat}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Country:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select countries"
              value={filterCountry}
              onChange={setFilterCountry}
              style={{ width: "100%" }}
              size="small"
            >
              {countryOptions.map((cntry) => (
                <Select.Option key={cntry} value={cntry}>
                  {cntry}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Date Range:</label>
            <RangePicker
              onChange={(dates) => {
                if (dates) {
                  setFilterDateRange(dates);
                } else {
                  setFilterDateRange([null, null]);
                }
              }}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <Button
              onClick={resetFilters}
              style={{ marginTop: "22px", width: "100%" }}
              size="small"
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Row>

      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          style={{ marginBottom: "20px" }}
        />
      )}

      <Table
        columns={columns}
        dataSource={filteredWebsites.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )}
        rowKey="id"
        pagination={false}
        style={{ overflowX: "auto" }}
      />

      <div
        className="pagination-controls"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredWebsites.length}
          onChange={(page) => setCurrentPage(page)}
          style={{ marginBottom: 10 }}
        />

        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Items per page:</span>
          <Select
            value={pageSize}
            style={{ width: 80 }}
            onChange={handlePageSizeChange}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
      </div>

      <Modal
        title="Rejection Reason"
        open={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="close"
            icon={<CloseCircleFilled />}
            type="link"
            onClick={() => setIsModalVisible(false)}
          >
            Close
          </Button>,
        ]}
      >
        <p>{selectedReason || "No reason provided"}</p>
      </Modal>
    </div>
  );
};

export default MyWebsites;

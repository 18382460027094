// reducers/websiteReducer.js

import {
    FETCH_WEBSITES_REQUEST,
    FETCH_WEBSITES_SUCCESS,
    FETCH_WEBSITES_FAILURE,
} from '../constants/websiteActionTypes';

const initialState = {
    loading: false,
    websites: [],
    error: '',
};

const websiteReducer = (state = initialState, action) => {
    console.log("Action received in websiteReducer:", action); // Log each action received

    switch (action.type) {
        case FETCH_WEBSITES_REQUEST:
            console.log("FETCH_WEBSITES_REQUEST - Setting loading to true");
            return { ...state, loading: true };
        case FETCH_WEBSITES_SUCCESS:
            console.log("FETCH_WEBSITES_SUCCESS - Websites loaded:", action.payload);
            return { loading: false, websites: action.payload, error: '' };
        case FETCH_WEBSITES_FAILURE:
            console.error("FETCH_WEBSITES_FAILURE - Error loading websites:", action.payload);
            return { loading: false, websites: [], error: action.payload };
        default:
            return state;
    }
};

export default websiteReducer;

import {
    FETCH_WEBSITE_REQUEST,
    FETCH_WEBSITE_SUCCESS,
    FETCH_WEBSITE_FAILURE,
    UPDATE_WEBSITE_REQUEST,
    UPDATE_WEBSITE_SUCCESS,
    UPDATE_WEBSITE_FAILURE
} from '../constants/editwebsiteActionTypes';

const initialState = {
    website: null, // Make sure initial state matches the conditional check in the component
    loading: false,
    error: null,
    updateSuccess: false,
};

const websiteReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_WEBSITE_REQUEST:
            console.log('Reducer: FETCH_WEBSITE_REQUEST');
            return { ...state, loading: true, error: null };
        case FETCH_WEBSITE_SUCCESS:
            console.log('Reducer: FETCH_WEBSITE_SUCCESS', action.payload);
            return { ...state, loading: false, website: action.payload };
        case FETCH_WEBSITE_FAILURE:
            console.error('Reducer: FETCH_WEBSITE_FAILURE', action.error);
            return { ...state, loading: false, error: action.error };
        case UPDATE_WEBSITE_REQUEST:
            return { ...state, loading: true, error: null, updateSuccess: false };
        case UPDATE_WEBSITE_SUCCESS:
            return { ...state, loading: false, updateSuccess: true };
        case UPDATE_WEBSITE_FAILURE:
            return { ...state, loading: false, error: action.error, updateSuccess: false };
        case 'RESET_UPDATE_SUCCESS':
                return { ...state, updateSuccess: false };
            
        default:
            return state;
    }
};

export default websiteReducer;

import React, { useState, useEffect, useRef } from "react";
import axios from "../api/api";
import { Table, Input, Button, Modal, Tag, Badge } from "antd";
import MessageList from "./MessageList";

const { TextArea } = Input;

const Chat = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [showModal, setShowModal] = useState(false);
  const ws = useRef(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const token = sessionStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }

    try {
      const res = await axios.get("/api/users/", {
        headers: { Authorization: `Token ${token}` },
      });
      const currentUserRole = sessionStorage.getItem("user_role");
      const userRoles = {};

      let filteredUsers = res.data.filter((user) =>
        currentUserRole === "admin"
          ? user.is_seller || user.is_buyer
          : currentUserRole === "seller"
          ? !user.is_seller && !user.is_buyer
          : currentUserRole === "buyer"
          ? !user.is_seller && !user.is_buyer
          : false
      );

      res.data.forEach((user) => {
        userRoles[user.email] = user.is_seller
          ? "seller"
          : user.is_buyer
          ? "buyer"
          : "admin";
      });

      const unreadCountsRes = await axios.get("/api/chat/unread_count/", {
        headers: { Authorization: `Token ${token}` },
      });
      const unreadCounts = unreadCountsRes.data;

      filteredUsers = filteredUsers.map((user) => ({
        ...user,
        unread_count: unreadCounts[user.email] || 0,
      }));

      setUsers(filteredUsers);
      sessionStorage.setItem("userRoles", JSON.stringify(userRoles));
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  };

  const getUserRoleStyle = (role) => {
    switch (role) {
      case "admin":
        return { color: "blue", label: "Admin" };
      case "seller":
        return { color: "green", label: "Seller" };
      case "buyer":
        return { color: "purple", label: "Buyer" };
      default:
        return { color: "gray", label: "User" };
    }
  };

  const initiateChat = async (user) => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(`/api/chat/room/${user.id}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      const roomId = response.data.room_id;

      if (selectedUser && selectedUser.roomId !== roomId) {
        disconnectWebSocket();
      }

      setSelectedUser({ ...user, roomId });
      loadMessages(roomId);
      connectWebSocket(roomId);
      setShowModal(true);
    } catch (error) {
      console.error("Failed to create or fetch chat room:", error);
    }
  };

  const connectWebSocket = (roomId) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) return;
    ws.current = new WebSocket(`wss://writeshuttle.com/ws/chat/${roomId}/`);
    ws.current.onopen = () => console.log("WebSocket connected");
    ws.current.onmessage = (event) => {
      const newMessage = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      if (selectedUser?.email !== newMessage.sender__email) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.email === newMessage.sender__email
              ? { ...user, unread_count: (user.unread_count || 0) + 1 }
              : user
          )
        );
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket disconnected");
      ws.current = null;
    };

    ws.current.onerror = (error) => {
      console.error("WebSocket error", error);
      ws.current.close();
    };
  };

  const sendMessage = async () => {
    if (
      input.trim() &&
      selectedUser &&
      ws.current &&
      ws.current.readyState === WebSocket.OPEN
    ) {
      const newMessage = {
        content: input,
        sender__email: sessionStorage.getItem("user_id"),
        timestamp: new Date().toISOString(),
      };

      ws.current.send(JSON.stringify(newMessage));
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput("");

      try {
        const token = sessionStorage.getItem("token");
        await axios.post(
          `/api/chat/send/${selectedUser.roomId}/`,
          { content: input },
          {
            headers: { Authorization: `Token ${token}` },
          }
        );
      } catch (error) {
        console.error("Failed to save message in the database:", error);
      }
    } else {
      console.warn(
        "Cannot send message: WebSocket is not open or no user selected."
      );
    }
  };

  const disconnectWebSocket = () => {
    if (ws.current) {
      ws.current.close();
      ws.current = null;
    }
  };

  const loadMessages = async (roomId) => {
    const token = sessionStorage.getItem("token");
    try {
      const response = await axios.get(`/api/chat/messages/${roomId}/`, {
        headers: { Authorization: `Token ${token}` },
      });
      setMessages(response.data.messages);
    } catch (error) {
      console.error("Failed to load messages:", error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    setMessages([]);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (role) => {
        const { color, label } = getUserRoleStyle(role);
        return <Tag color={color}>{label}</Tag>;
      },
    },
    {
      title: "Unread Messages",
      dataIndex: "unread_count",
      key: "unread_count",
      render: (count) => (
        <Badge
          count={count}
          offset={[10, 0]}
          style={{ backgroundColor: "#f5222d" }}
        />
      ),
    },
  ];

  const tableData = users.map((user) => {
    const userRoles = JSON.parse(sessionStorage.getItem("userRoles") || "{}");
    const role = userRoles[user.email];
    return {
      key: user.id,
      email: user.email,
      role: role,
      ...user,
    };
  });

  return (
    <div
      style={{
        background: "linear-gradient(135deg, #6e85d3, #d1a3d8)",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <h2 style={{ textAlign: "center", color: "white" }}>Chat</h2>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        onRow={(record) => ({
          onClick: () => initiateChat(record),
        })}
        rowClassName="clickable-row"
        style={{ cursor: "pointer", borderRadius: "10px", overflow: "hidden" }}
      />
      <Modal
        title={`Chat with ${selectedUser?.email || "User"}`}
        open={showModal}
        onCancel={handleCloseModal}
        footer={null}
        bodyStyle={{
          background: "linear-gradient(135deg, #ffffff, #f6e6ff)",
        }}
      >
        <MessageList
          messages={messages}
          currentUser={sessionStorage.getItem("user_id")}
          currentUserRole={sessionStorage.getItem("user_role")}
        />
        <TextArea
          rows={4}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          style={{ borderRadius: "5px", marginTop: "10px" }}
        />
        <Button
          type="primary"
          onClick={sendMessage}
          style={{
            marginTop: "10px",
            borderRadius: "5px",
            background: "linear-gradient(135deg, #6e85d3, #d1a3d8)",
            borderColor: "transparent",
          }}
        >
          Send
        </Button>
      </Modal>
    </div>
  );
};

export default Chat;

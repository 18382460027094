// websiteActionTypes.js
export const FETCH_WEBSITES_REQUEST = 'FETCH_WEBSITES_REQUEST';
export const FETCH_WEBSITES_SUCCESS = 'FETCH_WEBSITES_SUCCESS';
export const FETCH_WEBSITES_FAILURE = 'FETCH_WEBSITES_FAILURE';

export const APPROVE_WEBSITE_REQUEST = 'APPROVE_WEBSITE_REQUEST';
export const APPROVE_WEBSITE_SUCCESS = 'APPROVE_WEBSITE_SUCCESS';
export const APPROVE_WEBSITE_FAILURE = 'APPROVE_WEBSITE_FAILURE';

export const REJECT_WEBSITE_REQUEST = 'REJECT_WEBSITE_REQUEST';
export const REJECT_WEBSITE_SUCCESS = 'REJECT_WEBSITE_SUCCESS';
export const REJECT_WEBSITE_FAILURE = 'REJECT_WEBSITE_FAILURE';

export const TOGGLE_WEBSITE_STATUS_REQUEST = 'TOGGLE_WEBSITE_STATUS_REQUEST';
export const TOGGLE_WEBSITE_STATUS_SUCCESS = 'TOGGLE_WEBSITE_STATUS_SUCCESS';
export const TOGGLE_WEBSITE_STATUS_FAILURE = 'TOGGLE_WEBSITE_STATUS_FAILURE';

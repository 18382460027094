import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { rehydrateAuth } from "../actions/authActions";
import { Container, Navbar, Nav, Button, Offcanvas } from "react-bootstrap";

const MainLayout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role } = useSelector((state) => state.auth);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [drawerVisible, setDrawerVisible] = useState(false);

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const user = sessionStorage.getItem("user");
    const role = sessionStorage.getItem("role");

    if (token && user && role) {
      dispatch(
        rehydrateAuth({
          token,
          user: JSON.parse(user),
          role,
        })
      );
    }
  }, [dispatch]);

  const handleLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("role");
    navigate("/login");
  };

  const menuItems = [
    {
      label: (
        <Link to="/dashboard" className="nav-link">
          Home
        </Link>
      ),
      key: "1",
    },
    role === "admin" && {
      label: (
        <Link to="/dashboard/manage-users" className="nav-link">
          Manage Users
        </Link>
      ),
      key: "3",
    },
    role === "admin" && {
      label: (
        <Link to="/dashboard/manage-websites" className="nav-link">
          Manage Websites
        </Link>
      ),
      key: "4",
    },
    role === "admin" && {
      label: (
        <Link to="/dashboard/add-website" className="nav-link">
          Add New Website
        </Link>
      ),
      key: "5",
    },
    role === "admin" && {
      label: (
        <Link to="/dashboard/seller-orders" className="nav-link">
          My Cart
        </Link>
      ),
      key: "6",
    },
    role === "admin" && {
      label: (
        <Link to="/dashboard/my-websites" className="nav-link">
          My Websites
        </Link>
      ),
      key: "7",
    },
    role === "admin" && {
      label: (
        <Link to="/dashboard/manage-countries" className="nav-link">
          Global Parameters
        </Link>
      ),
      key: "8",
    },
    role === "seller" && {
      label: (
        <Link to="/dashboard/my-websites" className="nav-link">
          My Websites
        </Link>
      ),
      key: "9",
    },
    role === "seller" && {
      label: (
        <Link to="/dashboard/add-website" className="nav-link">
          Add Website
        </Link>
      ),
      key: "10",
    },
    role === "seller" && {
      label: (
        <Link to="/dashboard/seller-orders" className="nav-link">
          My Cart
        </Link>
      ),
      key: "11",
    },
    role === "buyer" && {
      label: (
        <Link to="/dashboard/buyer-orders" className="nav-link">
          My Cart
        </Link>
      ),
      key: "13",
    },
    {
      label: (
        <Link to="/chat" className="nav-link">
          Help
        </Link>
      ),
      key: "14",
    },
  ].filter(Boolean);

  // Resize handling
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Container
      fluid
      className="vh-100 d-flex flex-column"
      style={{
        fontFamily: "inherit",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Navbar
        variant="dark"
        expand="lg"
        className="p-3 shadow"
        style={{
          borderRadius: "12px",
          margin: "10px 0",
          background: "linear-gradient(to right, #3498db, #8e44ad)",
        }}
      >
        {isMobile ? (
          <>
            <Button
              variant="outline-light"
              onClick={() => setDrawerVisible(true)}
              style={{
                borderRadius: "50px",
                padding: "10px 20px",
                fontWeight: "bold",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#3498db")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
            >
              Menu
            </Button>

            <Offcanvas
              show={drawerVisible}
              onHide={() => setDrawerVisible(false)}
              placement="end"
              style={{
                backgroundColor: "#2c3e50",
                borderRadius: "10px 0 0 10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title
                  style={{
                    color: "#fff",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body
                style={{
                  background: "linear-gradient(to right, #3498db, #8e44ad)",
                  color: "#fff",
                  padding: "20px",
                  borderRadius: "0 0 10px 10px",
                }}
              >
                <Nav className="flex-column">
                  {menuItems.map((item) => (
                    <Nav.Item key={item.key} className="mb-3">
                      <span
                        style={{
                          fontWeight: "500",
                          fontSize: "1.1rem",
                          color: "#fff",
                        }}
                        className="d-block"
                      >
                        {item.label}
                      </span>
                    </Nav.Item>
                  ))}
                  <Button
                    variant="outline-light"
                    className="mt-4"
                    onClick={handleLogout}
                    style={{
                      borderRadius: "20px",
                      padding: "10px 20px",
                      fontWeight: "bold",
                    }}
                    onMouseEnter={(e) => (e.target.style.backgroundColor = "#e74c3c")}
                    onMouseLeave={(e) => (e.target.style.backgroundColor = "transparent")}
                  >
                    Logout
                  </Button>
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </>
        ) : (
          <Nav className="ms-auto">
            {menuItems.map((item) => (
              <Nav.Item key={item.key} className="mx-2">
                {item.label}
              </Nav.Item>
            ))}
            <Button
              variant="outline-light"
              className="rounded-pill ms-3"
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Nav>
        )}
      </Navbar>

      <Container
        fluid
        className="flex-grow-1 p-3"
        style={{
          background: "#fff",
          borderRadius: "8px",
          boxShadow: "0 1px 6px rgba(0, 0, 0, 0.1)",
          fontFamily: "inherit",
        }}
      >
        {children}
      </Container>
    </Container>
  );
};

export default MainLayout;

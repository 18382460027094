// websiteActionTypes.js

// Fetch websites action types
export const FETCH_WEBSITES_REQUEST = 'FETCH_WEBSITES_REQUEST';
export const FETCH_WEBSITES_SUCCESS = 'FETCH_WEBSITES_SUCCESS';
export const FETCH_WEBSITES_FAILURE = 'FETCH_WEBSITES_FAILURE';

// Add website action types
export const ADD_WEBSITE_REQUEST = 'ADD_WEBSITE_REQUEST';
export const ADD_WEBSITE_SUCCESS = 'ADD_WEBSITE_SUCCESS';
export const ADD_WEBSITE_FAILURE = 'ADD_WEBSITE_FAILURE';

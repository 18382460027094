import React, { useState, useEffect } from "react";
import axios from "../api/api";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Space,
  Tag,
  Card,
  Row,
  Col,
  Statistic,
  notification,
  Select,
  Pagination,
  InputNumber,
  DatePicker,
} from "antd";

const ManageWebsites = () => {
  const [websites, setWebsites] = useState([]);
  const [rejectionModalVisible, setRejectionModalVisible] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [currentWebsiteId, setCurrentWebsiteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPage")) || 1
  );
  const [pageSize, setPageSize] = useState(5);
  const { RangePicker } = DatePicker;
  // Filter states
  const [filterDa, setFilterDa] = useState([0, 100]);
  const [filterDr, setFilterDr] = useState([0, 100]);
  const [filterPrice, setFilterPrice] = useState([0, 10000]);
  const [filterTraffic, setFilterTraffic] = useState([0, 10000000]);
  const [filterLanguage, setFilterLanguage] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterCountry, setFilterCountry] = useState([]);
  const [filterName, setFilterName] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterDateRange, setFilterDateRange] = useState([null, null]);

  // Options for filter dropdowns
  const [languageOptions, setLanguageOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  useEffect(() => {
    fetchWebsites();
  }, []);

  useEffect(() => {
    const maxPage = Math.ceil(websites.length / pageSize);
    if (currentPage > maxPage && maxPage > 0) {
      setCurrentPage(maxPage);
    }
    localStorage.setItem("currentPage", currentPage);
  }, [currentPage, websites.length, pageSize]);

  const fetchWebsites = () => {
    axios
      .get("/api/websites/", {
        headers: { Authorization: `Token ${sessionStorage.getItem("token")}` },
      })
      .then((res) => {
        const sortedWebsites = res.data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setWebsites(sortedWebsites);

        const uniqueLanguages = new Set();
        const uniqueCategories = new Set();
        const uniqueCountries = new Set();
        res.data.forEach((site) => {
          site.language_names.forEach((lang) => uniqueLanguages.add(lang.name));
          site.category_names.forEach((cat) => uniqueCategories.add(cat.name));
          site.country_names.forEach((cntry) =>
            uniqueCountries.add(cntry.name)
          );
        });
        setLanguageOptions([...uniqueLanguages]);
        setCategoryOptions([...uniqueCategories]);
        setCountryOptions([...uniqueCountries]);

        notification.success({
          message: "Websites Loaded",
          description: "Websites data loaded successfully.",
          duration: 2,
        });
      })
      .catch((err) => {
        console.error("Failed to fetch websites:", err);
        notification.error({
          message: "Error",
          description: "Error fetching websites. Please try again.",
          duration: 2,
        });
      });
  };

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleApprove = (id) => {
    axios
      .post(
        "/api/websites/approve/",
        { website_id: id },
        {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setWebsites(
          websites.map((site) =>
            site.id === id
              ? {
                  ...site,
                  is_approved: true,
                  is_rejected: false,
                  rejection_reason: "",
                }
              : site
          )
        );
        notification.success({
          message: "Website Approved",
          description: "Website approved successfully!",
          duration: 2,
        });
      })
      .catch((err) => {
        console.error("Failed to approve website:", err);
        notification.error({
          message: "Error",
          description: "Error approving website. Please try again.",
          duration: 2,
        });
      });
  };

  const showRejectionModal = (id) => {
    setCurrentWebsiteId(id);
    setRejectionModalVisible(true);
  };

  const handleReject = () => {
    if (!rejectionReason) {
      notification.error({
        message: "Rejection Reason Required",
        description: "Please provide a rejection reason.",
        duration: 2,
      });
      return;
    }

    axios
      .post(
        "/api/websites/reject/",
        { website_id: currentWebsiteId, rejection_reason: rejectionReason },
        {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setWebsites(
          websites.map((site) =>
            site.id === currentWebsiteId
              ? {
                  ...site,
                  is_approved: false,
                  is_rejected: true,
                  rejection_reason: rejectionReason,
                }
              : site
          )
        );
        setRejectionModalVisible(false);
        setRejectionReason("");
        notification.info({
          message: "Website Rejected",
          description: "Website rejected successfully.",
          duration: 2,
        });
      })
      .catch((err) => {
        console.error("Failed to reject website:", err);
        notification.error({
          message: "Error",
          description: "Error rejecting website. Please try again.",
          duration: 2,
        });
      });
  };

  const handleToggleActive = (id, currentStatus) => {
    const newStatus = !currentStatus;
    axios
      .put(
        `/api/websites/toggle-active/${id}/`,
        { is_active: newStatus },
        {
          headers: {
            Authorization: `Token ${sessionStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setWebsites(
          websites.map((site) =>
            site.id === id ? { ...site, is_active: newStatus } : site
          )
        );
        notification.success({
          message: "Website Status Updated",
          description: `Website has been marked as ${
            newStatus ? "active" : "inactive"
          } successfully!`,
          duration: 2,
        });
      })
      .catch((err) => {
        console.error("Failed to update website status:", err);
        notification.error({
          message: "Error",
          description: "Error updating website status. Please try again.",
          duration: 2,
        });
      });
  };

  // Filtered websites based on selected criteria
  const filteredWebsites = websites.filter((site) => {
    const daMatch =
      (filterDa[0] === 0 && filterDa[1] === 100) ||
      (site.domain_authority >= filterDa[0] &&
        site.domain_authority <= filterDa[1]);
    const drMatch =
      (filterDr[0] === 0 && filterDr[1] === 100) ||
      (site.domain_rating >= filterDr[0] && site.domain_rating <= filterDr[1]);
    const priceMatch =
      (filterPrice[0] === 0 && filterPrice[1] === 10000) ||
      (site.price >= filterPrice[0] && site.price <= filterPrice[1]);
    const trafficMatch =
      (filterTraffic[0] === 0 && filterTraffic[1] === 10000000) ||
      (site.monthly_traffic >= filterTraffic[0] &&
        site.monthly_traffic <= filterTraffic[1]);
    const languageMatch =
      filterLanguage.length === 0 ||
      filterLanguage.some((lang) =>
        site.language_names.some((language) => language.name === lang)
      );
    const categoryMatch =
      filterCategory.length === 0 ||
      filterCategory.some((cat) =>
        site.category_names.some((category) => category.name === cat)
      );
    const countryMatch =
      filterCountry.length === 0 ||
      filterCountry.some((cntry) =>
        site.country_names.some((country) => country.name === cntry)
      );
    const nameMatch =
      filterName === "" ||
      site.name.toLowerCase().includes(filterName.toLowerCase());
    const idMatch = filterId === "" || site.id.toString() === filterId;
    // Check if filterDateRange has valid dates before comparing
    const dateMatch =
      !filterDateRange[0] ||
      (new Date(site.created_at) >= new Date(filterDateRange[0]) &&
        new Date(site.created_at) <= new Date(filterDateRange[1]));

    return (
      daMatch &&
      drMatch &&
      priceMatch &&
      trafficMatch &&
      languageMatch &&
      categoryMatch &&
      countryMatch &&
      nameMatch &&
      dateMatch &&
      idMatch
    );
  });

  const resetFilters = () => {
    setFilterDa([0, 100]);
    setFilterDr([0, 100]);
    setFilterPrice([0, 10000]);
    setFilterTraffic([0, 10000000]);
    setFilterLanguage([]);
    setFilterCategory([]);
    setFilterCountry([]);
    setFilterName("");
    setFilterId("");
    setFilterDateRange([null, null]); // Reset the date range here
  };

  const columns = [
    {
      title: "Site No.",
      key: "index",
      render: (text, record, index) =>
        websites.length - ((currentPage - 1) * pageSize + index),
    },
    {
      title: "Website ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Website Name",
      dataIndex: "name",
      key: "name",
      render: (text) => (
        <h3 style={{ fontFamily: "SF Pro" }}>
          {text.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase())}
        </h3>
      ),
    },
    {
      title: "Seller ID",
      dataIndex: "seller_id",
      key: "seller_id",
    },
    {
      title: "Seller",
      dataIndex: "seller_email",
      key: "sellerEmail",
    },
    {
      title: "Countries",
      key: "countries",
      dataIndex: "country_names",
      render: (countries) =>
        (countries || []).map((country, index) => (
          <Tag color="blue" key={`country-${index}`}>
            {country.name}
          </Tag>
        )),
    },
    {
      title: "Languages",
      key: "languages",
      dataIndex: "language_names",
      render: (languages) =>
        (languages || []).map((language, index) => (
          <Tag color="green" key={`language-${index}`}>
            {language.name}
          </Tag>
        )),
    },
    {
      title: "Categories",
      key: "categories",
      dataIndex: "category_names",
      render: (categories) =>
        (categories || []).map((category, index) => (
          <Tag color="purple" key={`category-${index}`}>
            {category.name}
          </Tag>
        )),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      render: (url) => (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      ),
    },
    {
      title: "Monthly Traffic",
      dataIndex: "monthly_traffic",
      key: "monthly_traffic",
      render: (value) => value || "N/A",
    },
    {
      title: "Link Type",
      dataIndex: "link_type",
      key: "link_type",
      render: (value) => value || "N/A",
    },
    {
      title: "Number of Words",
      dataIndex: "number_of_words",
      key: "number_of_words",
      render: (value) => value || "N/A",
    },
    {
      title: "Domain Rating (DR)",
      dataIndex: "domain_rating",
      key: "domain_rating",
      render: (value) => value || "N/A",
    },
    {
      title: "Domain Authority (DA)",
      dataIndex: "domain_authority",
      key: "domain_authority",
      render: (value) => value || "N/A",
    },
    {
      title: "Page Authority (PA)",
      dataIndex: "page_authority",
      key: "page_authority",
      render: (value) => value || "N/A",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description) => <span>{description}</span>,
    },
    {
      title: "Date Added",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => {
        const formattedDate = new Date(created_at).toLocaleString("en-UK", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        });
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Status",
      key: "status",
      render: (text, record) => (
        <>
          {record.is_approved ? (
            <Tag color="green">Approved</Tag>
          ) : record.is_rejected ? (
            <>
              <Tag color="red">Rejected</Tag>
              {record.rejection_reason && (
                <p style={{ color: "red", fontWeight: "bold" }}>
                  Reason: {record.rejection_reason}
                </p>
              )}
            </>
          ) : (
            <Tag color="blue">Pending Approval</Tag>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          {!record.is_approved && !record.is_rejected && (
            <>
              <Button type="primary" onClick={() => handleApprove(record.id)}>
                Approve
              </Button>
              <Button
                type="danger"
                onClick={() => showRejectionModal(record.id)}
              >
                Reject
              </Button>
            </>
          )}
          <Button
            type="primary"
            danger={record.is_active}
            style={{
              backgroundColor: !record.is_active ? "#52c41a" : undefined,
              borderColor: !record.is_active ? "#52c41a" : undefined,
            }}
            onClick={() => handleToggleActive(record.id, record.is_active)}
          >
            {record.is_active ? "Deactivate" : "Activate"}
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: "20px", marginTop: "20px" }}>
      <Row gutter={16} style={{ marginBottom: "20px" }}>
        <Col xs={24} sm={12} md={8}>
          <Card style={{ backgroundColor: "#E1F5FE", borderColor: "#29B6F6" }}>
            <Statistic
              title="Total Websites"
              value={websites.length}
              valueStyle={{ color: "#0277BD" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card style={{ backgroundColor: "#E8F5E9", borderColor: "#66BB6A" }}>
            <Statistic
              title="Active Websites"
              value={websites.filter((site) => site.is_active).length}
              valueStyle={{ color: "#3f8600" }}
            />
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card style={{ backgroundColor: "#FFEBEE", borderColor: "#E57373" }}>
            <Statistic
              title="Inactive Websites"
              value={websites.filter((site) => !site.is_active).length}
              valueStyle={{ color: "#cf1322" }}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginBottom: "20px", flexWrap: "wrap" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col xs={12} sm={8} md={4} lg={3}>
            <label>DA Range:</label>
            <InputNumber
              min={0}
              max={100}
              value={filterDa[0]}
              onChange={(value) => setFilterDa([value, filterDa[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={100}
              value={filterDa[1]}
              onChange={(value) => setFilterDa([filterDa[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>DR Range:</label>
            <InputNumber
              min={0}
              max={100}
              value={filterDr[0]}
              onChange={(value) => setFilterDr([value, filterDr[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={100}
              value={filterDr[1]}
              onChange={(value) => setFilterDr([filterDr[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Price Range:</label>
            <InputNumber
              min={0}
              max={10000}
              value={filterPrice[0]}
              onChange={(value) => setFilterPrice([value, filterPrice[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={10000}
              value={filterPrice[1]}
              onChange={(value) => setFilterPrice([filterPrice[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Traffic Range:</label>
            <InputNumber
              min={0}
              max={10000000}
              value={filterTraffic[0]}
              onChange={(value) => setFilterTraffic([value, filterTraffic[1]])}
              style={{ width: "100%" }}
              size="small"
            />
            <InputNumber
              min={0}
              max={10000000}
              value={filterTraffic[1]}
              onChange={(value) => setFilterTraffic([filterTraffic[0], value])}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>Name:</label>
            <Input
              placeholder="Search by name"
              value={filterName}
              onChange={(e) => setFilterName(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <label>ID:</label>
            <Input
              placeholder="Search by ID"
              value={filterId}
              onChange={(e) => setFilterId(e.target.value)}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Language:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select languages"
              value={filterLanguage}
              onChange={setFilterLanguage}
              style={{ width: "100%" }}
              size="small"
            >
              {languageOptions.map((lang) => (
                <Select.Option key={lang} value={lang}>
                  {lang}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Category:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select categories"
              value={filterCategory}
              onChange={setFilterCategory}
              style={{ width: "100%" }}
              size="small"
            >
              {categoryOptions.map((cat) => (
                <Select.Option key={cat} value={cat}>
                  {cat}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Country:</label>
            <Select
              mode="multiple"
              allowClear
              placeholder="Select countries"
              value={filterCountry}
              onChange={setFilterCountry}
              style={{ width: "100%" }}
              size="small"
            >
              {countryOptions.map((cntry) => (
                <Select.Option key={cntry} value={cntry}>
                  {cntry}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col xs={12} sm={8} md={6} lg={4}>
            <label>Date Range:</label>
            <RangePicker
              onChange={(dates) => {
                if (dates) {
                  setFilterDateRange(dates);
                } else {
                  setFilterDateRange([null, null]);
                }
              }}
              style={{ width: "100%" }}
              size="small"
            />
          </Col>

          <Col xs={12} sm={8} md={4} lg={3}>
            <Button
              onClick={resetFilters}
              style={{ marginTop: "22px", width: "100%" }}
              size="small"
            >
              Reset
            </Button>
          </Col>
        </Row>
      </Row>

      <Table
        columns={columns}
        dataSource={filteredWebsites.slice(
          (currentPage - 1) * pageSize,
          currentPage * pageSize
        )}
        rowKey="id"
        pagination={false}
        style={{ overflowX: "auto" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredWebsites.length}
          onChange={handlePageChange}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ marginRight: 8 }}>Items per page:</span>
          <Select
            value={pageSize}
            style={{ width: 80 }}
            onChange={handlePageSizeChange}
          >
            <Select.Option value={5}>5</Select.Option>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={50}>50</Select.Option>
          </Select>
        </div>
      </div>

      {/* Rejection Modal */}
      <Modal
        title="Reject Website"
        open={rejectionModalVisible}
        onOk={handleReject}
        onCancel={() => {
          setRejectionModalVisible(false);
          setRejectionReason("");
        }}
      >
        <Form layout="vertical">
          <Form.Item label="Rejection Reason" required>
            <Input.TextArea
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageWebsites;

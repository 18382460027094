import axios from '../api/api';  // Using the same axios instance as other actions
import { toast } from 'react-toastify';
import {
    FETCH_ORDERS,
    UPDATE_ORDER,
    DELETE_ORDER,
    SET_LOADING,
} from '../constants/orderActionsTypes';  // Import action types

const ORDERS_ENDPOINT = '/api/buyer/orders/';  // Define endpoint directly in the file

// Fetch Orders
export const fetchOrders = () => async (dispatch) => {
    dispatch({ type: SET_LOADING, payload: true });
    try {
        const response = await axios.get(ORDERS_ENDPOINT, {
            headers: { Authorization: `Token ${sessionStorage.getItem('token')}` },
        });
        dispatch({ type: FETCH_ORDERS, payload: response.data });
    } catch (error) {
        console.error('Error fetching orders:', error);
    } finally {
        dispatch({ type: SET_LOADING, payload: false });
    }
};

// Update Order
export const updateOrder = (orderId, updatedRequirements) => async (dispatch) => {
    try {
        const response = await axios.put(
            `${ORDERS_ENDPOINT}${orderId}/`,
            { special_requests: updatedRequirements },
            { headers: { Authorization: `Token ${sessionStorage.getItem('token')}` } }
        );
        dispatch({ type: UPDATE_ORDER, payload: response.data });
        toast.success('Order updated successfully!');
    } catch (error) {
        console.error('Error updating order:', error);
        toast.error('Failed to update order.');
    }
};

// Delete Order
export const deleteOrder = (orderId) => async (dispatch) => {
    try {
        await axios.delete(`${ORDERS_ENDPOINT}${orderId}/`, {
            headers: { Authorization: `Token ${sessionStorage.getItem('token')}` },
        });
        dispatch({ type: DELETE_ORDER, payload: orderId });
        toast.success('Order deleted successfully!');
    } catch (error) {
        console.error('Error deleting order:', error);
        toast.error('Failed to delete order.');
    }
};

import axios from '../api/api';
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REHYDRATE_AUTH,
} from '../constants/actionTypes';

// Helper to store user data securely in sessionStorage
const storeUserData = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user_id', user.id);
    sessionStorage.setItem('user_role', user.type);
    sessionStorage.setItem('user_email', user.email);
};

// Register User
export const register = ({ email, password, password2, type }) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ email, password, password2, type });

    try {
        const response = await axios.post('/api/register/', body, config);

        if (response.data) {
            dispatch({
                type: REGISTER_SUCCESS,
                payload: response.data,
            });
            console.log('Registration successful:', response.data);
            return true; // Registration was successful
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        let errorMsg = 'Registration failed';
        if (error.response) {
            errorMsg = error.response.data.detail || error.response.data.error || 'Registration failed with server error';
            console.error('Registration failed with status:', error.response.status);
        } else if (error.request) {
            errorMsg = 'No response from server. Please try again later.';
            console.error('No response received:', error.request);
        } else {
            errorMsg = error.message;
            console.error('Error setting up registration request:', errorMsg);
        }

        dispatch({
            type: REGISTER_FAIL,
            payload: errorMsg,
        });

        return false; // Registration failed
    }
};

// Login User
export const login = (email, password) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        },
    };

    const body = JSON.stringify({ email, password });

    try {
        const response = await axios.post('/api/login/', body, config);

        if (response.data && response.data.token && response.data.user && response.data.user.id) {
            const { token, user } = response.data;

            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data,
            });

            // Store token and user data in sessionStorage
            storeUserData(token, user);

            console.log('Login successful:', response.data);
            return true; // Login was successful
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        let errorMsg = 'Login failed';
        if (error.response) {
            if (error.response.status === 401) {
                errorMsg = 'Invalid email or password';
                console.error('Login failed: Unauthorized access');
            } else {
                errorMsg = error.response.data.detail || error.response.data.error || 'Login failed with server error';
                console.error('Login failed with status:', error.response.status);
            }
        } else if (error.request) {
            errorMsg = 'No response from server. Please try again later.';
            console.error('No response received:', error.request);
        } else {
            errorMsg = error.message;
            console.error('Error setting up login request:', errorMsg);
        }

        dispatch({
            type: LOGIN_FAIL,
            payload: errorMsg,
        });

        return false; // Login failed
    }
};

// Logout User
export const logout = () => (dispatch) => {
    // Clear session storage
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_id');
    sessionStorage.removeItem('user_role');
    sessionStorage.removeItem('user_email');
    sessionStorage.clear();

    dispatch({
        type: LOGOUT,
    });

    console.log('User logged out successfully');
};

// Token Expiration Handling
export const checkTokenExpiration = () => (dispatch) => {
    const token = sessionStorage.getItem('token');
    if (token) {
        try {
            // Decode the JWT token and check expiration
            const tokenData = JSON.parse(atob(token.split('.')[1]));
            const currentTime = Date.now() / 1000;

            if (tokenData.exp < currentTime) {
                console.warn('Token expired, logging out user.');
                dispatch(logout()); // Token expired, logout user
            }
        } catch (error) {
            console.error('Error parsing token:', error);
            dispatch(logout()); // Log out user if token is malformed
        }
    }
};

// Action to rehydrate authentication state from sessionStorage
export const rehydrateAuth = (authData) => {
    return {
        type: REHYDRATE_AUTH,
        payload: {
            token: authData.token,
            user: authData.user,
            role: authData.role,
        },
    };
};

// Action to handle login success
export const loginSuccess = (userData) => {
    return {
        type: LOGIN_SUCCESS,
        payload: userData,
    };
};

import {
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_SELLER_WEBSITES_REQUEST,
    FETCH_SELLER_WEBSITES_SUCCESS,
    FETCH_SELLER_WEBSITES_FAILURE,
    TOGGLE_USER_STATUS_REQUEST,
    TOGGLE_USER_STATUS_SUCCESS,
    TOGGLE_USER_STATUS_FAILURE
} from '../constants/userActionTypes';

const initialState = {
    users: [],
    selectedUser: null,
    websites: [],
    loading: false,
    error: null
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
        case FETCH_SELLER_WEBSITES_REQUEST:
        case TOGGLE_USER_STATUS_REQUEST:
            return { ...state, loading: true, error: null };

        case FETCH_USERS_SUCCESS:
            return { ...state, loading: false, users: action.payload };

        case FETCH_SELLER_WEBSITES_SUCCESS:
            return { ...state, loading: false, websites: action.payload };

        case TOGGLE_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: state.users.map(user =>
                    user.id === action.payload.userId ? { ...user, is_active: action.payload.isActive } : user
                )
            };

        case FETCH_USERS_FAILURE:
        case FETCH_SELLER_WEBSITES_FAILURE:
        case TOGGLE_USER_STATUS_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export default userReducer;

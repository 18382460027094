// reducers/authReducer.js
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT,
    REHYDRATE_AUTH,  // Added rehydration action type
} from '../constants/actionTypes';

const initialState = {
    token: sessionStorage.getItem('token'),
    isAuthenticated: !!sessionStorage.getItem('token'), // Check if token exists
    user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')) : null, // Load user from storage
    role: sessionStorage.getItem('role'), // Load role from storage
};

function authReducer(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case REGISTER_SUCCESS:
        case LOGIN_SUCCESS:
            sessionStorage.setItem('token', payload.token);
            sessionStorage.setItem('user', JSON.stringify(payload.user));
            sessionStorage.setItem('role', payload.user.type);
            return {
                ...state,
                isAuthenticated: true,
                user: payload.user,
                role: payload.user.type,
                token: payload.token,
            };
        case REGISTER_FAIL:
        case LOGIN_FAIL:
        case LOGOUT:
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('role');
            return {
                ...state,
                isAuthenticated: false,
                token: null,
                user: null,
                role: null,
            };
        case REHYDRATE_AUTH:  // Rehydrate the state from sessionStorage
            return {
                ...state,
                token: payload.token,
                user: payload.user,
                role: payload.role,
                isAuthenticated: true,
            };
        default:
            return state;
    }
}

export default authReducer;

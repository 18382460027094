import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PublicWebsites from './components/PublicWebsites';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import ManageUsers from './components/ManageUsers';
import ManageWebsites from './components/ManageWebsites';
import AddWebsite from './components/AddWebsite';
import EditWebsite from './components/EditWebsite';
import MyWebsites from './components/MyWebsites';
import PrivateRoute from './components/PrivateRoute';
import BuyerOrders from './components/BuyerOrders';
import SellerOrders from './components/SellerOrders';
import LandingPage from './components/LandingPage';
import MainLayout from './components/MainLayout';
import Chat from './components/Chat';
import CountryManager from './components/CountryManager';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            
            {/* Public websites route accessible by all authenticated users */}
            <Route
                path="/websites"
                element={<PrivateRoute element={() => <MainLayout><PublicWebsites /></MainLayout>} />}
            />
            
            {/* Dashboard - accessible by all authenticated users */}
            <Route
                path="/dashboard"
                element={<PrivateRoute element={() => <MainLayout><Dashboard /></MainLayout>} />}
            />

            {/* Seller-specific routes */}
            <Route
                path="/dashboard/my-websites"
                element={<PrivateRoute allowedRoles={['seller', 'admin']} element={() => <MainLayout><MyWebsites /></MainLayout>} />}
            />
            <Route
                path="/dashboard/add-website"
                element={<PrivateRoute allowedRoles={['seller', 'admin']} element={() => <MainLayout><AddWebsite /></MainLayout>} />}
            />
            <Route
                path="/dashboard/edit-website/:id"
                element={<PrivateRoute allowedRoles={['seller', 'admin']} element={() => <MainLayout><EditWebsite /></MainLayout>} />}
            />
            <Route
                path="/dashboard/seller-orders"
                element={<PrivateRoute allowedRoles={['seller', 'admin']} element={() => <MainLayout><SellerOrders /></MainLayout>} />}
            />

            {/* Admin-specific routes */}
            <Route
                path="/dashboard/manage-users"
                element={<PrivateRoute allowedRoles={['admin']} element={() => <MainLayout><ManageUsers /></MainLayout>} />}
            />
            <Route
                path="/dashboard/manage-websites"
                element={<PrivateRoute allowedRoles={['admin']} element={() => <MainLayout><ManageWebsites /></MainLayout>} />}
            />
            <Route
                path="/dashboard/manage-countries"
                element={<PrivateRoute allowedRoles={['admin']} element={() => <MainLayout><CountryManager /></MainLayout>} />}
            />

            {/* Buyer-specific routes */}
            <Route
                path="/dashboard/buyer-orders"
                element={<PrivateRoute allowedRoles={['buyer']} element={() => <MainLayout><BuyerOrders /></MainLayout>} />}
            />

            {/* Chat - accessible by all authenticated users */}
            <Route
                path="/chat"
                element={<PrivateRoute element={() => <MainLayout><Chat /></MainLayout>} />}
            />
        </Routes>
    );
};

export default AppRoutes;
